import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import getTheme from "../Theme";
import { Button, Card, CardActions, CardContent, FormControl, FormControlLabel, FormLabel, LinearProgress, Radio, RadioGroup, Stack, TextField, Typography, } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { 
//  ImageMultiScaleUpload,
//  scaleAndUploadImages,
scaleDownImage,
//  updateTypesBestEffort,
 } from "../ImageUtils";
import { AddAPhoto, 
//  PersonAdd,
//  Place,
//  Edit,
IosShare, } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import { ContentCard } from "./ContentCard";
export const NewChannelCard = (props) => {
    const canvasRef = useRef(null);
    const [addChannelTitle, setAddChannelTitle] = useState("");
    const [addChannelHelperTitle, setAddChannelHelperTitle] = useState(undefined);
    const [addChannelSubtitle, setAddChannelSubtitle] = useState("");
    const [addChannelHelperSubtitle, setAddChannelHelperSubtitle] = useState(undefined);
    const [addChannelText, setAddChannelText] = useState("");
    const [addChannelHelperText, setAddChannelHelperText] = useState(undefined);
    const [addChannelPrompt, setAddChannelPrompt] = useState("");
    const [addChannelHelperPrompt, setAddChannelHelperPrompt] = useState(undefined);
    const [addChannelImageUrl, setAddChannelImageUrl] = useState("");
    const [addChannelSaving, setAddChannelSaving] = useState(false);
    const [addChannelSavedOk, setAddChannelSavedOk] = useState(false);
    const [addChannelSaveError, setAddChannelSaveError] = useState(undefined);
    const hiddenFileInputAddChannel = useRef(null);
    const [fileInputListAddChannel, setFileInputListAddChannel] = useState();
    const [addChannelImageDataUrl, setAddChannelImageDataUrl] = useState("");
    const [access, setAccess] = useState();
    const validateAddChannelTitle = (title) => {
        setAddChannelSaveError(undefined);
        setAddChannelSaving(false);
        if (!title) {
            setAddChannelHelperTitle(undefined);
            return;
        }
        if (title.includes("<")) {
            setAddChannelHelperTitle("Title cannot contain '<' character.");
        }
        else if (title.includes("\n")) {
            setAddChannelHelperTitle("Title cannot contain newline character.");
        }
        else {
            setAddChannelHelperTitle(undefined);
        }
    };
    const validateAddChannelSubtitle = (title) => {
        setAddChannelSaveError(undefined);
        setAddChannelSaving(false);
        if (!title) {
            setAddChannelHelperSubtitle(undefined);
            return;
        }
        if (title.includes("<")) {
            setAddChannelHelperSubtitle("Subtitle cannot contain '<' character.");
        }
        else if (title.includes("\n")) {
            setAddChannelHelperSubtitle("Subtitle cannot contain newline character.");
        }
        else {
            setAddChannelHelperSubtitle(undefined);
        }
    };
    const validateAddChannelText = (text) => {
        setAddChannelSaveError(undefined);
        setAddChannelSaving(false);
        if (!text) {
            setAddChannelHelperText(undefined);
            return;
        }
        if (text.includes("<")) {
            setAddChannelHelperText("Text cannot contain '<' character.");
        }
        else {
            setAddChannelHelperText(undefined);
        }
    };
    const validateAddChannelPrompt = (prompt) => {
        setAddChannelSaveError(undefined);
        setAddChannelSaving(false);
        if (!prompt) {
            setAddChannelHelperPrompt(undefined);
            return;
        }
        if (prompt.includes("<")) {
            setAddChannelHelperPrompt("Member question cannot contain '<' character.");
        }
        else if (prompt.includes("\n")) {
            setAddChannelHelperPrompt("Member question cannot contain newline character.");
        }
        else {
            setAddChannelHelperPrompt(undefined);
        }
    };
    const doPublish = () => {
        const ChannelId = newChannelId();
        console.log("doPublish: " + ChannelId);
        const handleError = (errorMessage) => {
            console.log("doPublish: handleError " + errorMessage);
            setTimeout(() => {
                setAddChannelSaveError(errorMessage);
                setAddChannelSavedOk(false);
                setAddChannelSaving(false);
            }, 300);
        };
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const handleOk = () => {
            console.log("doPublish: handleOk");
            setAddChannelSaving(false);
            setAddChannelSaveError(undefined);
            setTimeout(() => {
                setAddChannelSavedOk(true);
                setAddChannelTitle("");
                setAddChannelSubtitle("");
                setAddChannelText("");
                setAddChannelImageUrl(undefined);
                setAddChannelImageDataUrl("");
            }, 300);
        };
        setAddChannelSaveError(undefined);
        setAddChannelSavedOk(false);
        setAddChannelSaving(true);
        if (!props.auth || !props.auth.isSignedIn() || !props.auth.userId) {
            console.error("User not signed in.");
            handleError("User is not signed in.");
            return;
        }
        // TODO
        setAddChannelSaving(false);
        setAddChannelSaveError("Under Construction - Thank you for your patience.");
        /*
        const inputData: ChannelChannelInput = {
          title: addChannelTitle,
          subtitle: addChannelSubtitle,
          text: addChannelText,
          imageDataUrl: addChannelImageDataUrl,
          channelOwnerId: props.auth.userId,
          channelId: props.auth.userId,
          ChannelOwnerId: props.auth.userId,
          ChannelId,
          timestamp: new Date().getTime(),
          hasImage: false,
          hasVideo: false,
          hasMultipleFiles: false,
          multipleFileCount: 0,
        };
    
        if (!canvasRef?.current) {
          console.error("Invalid canvas reference.");
          handleError("Invalid canvas reference.");
          return;
        }
    
        const canvas = canvasRef?.current;
        const imageFiles = fileInputListAddChannel;
    
        if (!imageFiles || imageFiles.length <= 0) {
          console.log("doPublish: no images");
          inputData.hasImage = false;
          inputData.hasVideo = false;
    
          console.log("Saving Channel: " + JSON.stringify(inputData));
    
          saveChannelChannel(inputData, {
            okCallback: (clientDetailCode: number) => {
              console.log("okCallback: " + clientDetailCode);
              handleOk();
              return;
            },
            errorCallback: (clientDetailCode: number, errorMessage: string) => {
              handleError(errorMessage + " (" + clientDetailCode + ")");
              return;
            },
            authCallback: {
              isSignedIn: () => props.auth.isSignedIn(),
              bearer: () => props.auth.bearer,
              sequence: () => props.auth.sequence,
              setBearerAndSequence: (bearer: string, sequence: number) => {
                props.auth.setBearer(bearer);
                props.auth.setSequence(sequence);
              },
            },
          });
          return;
        }
    
        if (
          imageFiles[0].name.endsWith(".mov") ||
          imageFiles[0].name.endsWith(".mp4")
        ) {
          console.log("doPublish: video upload");
          inputData.hasImage = false;
          inputData.hasVideo = true;
    
          // TODO: Video upload
          return;
        }
    
        inputData.hasImage = true;
        inputData.hasVideo = false;
    
        if (imageFiles.length > 1) {
          inputData.hasMultipleFiles = true;
          inputData.multipleFileCount = imageFiles.length;
        }
    
        const partitionId = "u/" + props.channelOwnerId + "/" + props.channelId;
        const inputImageData: ImageMultiScaleUpload = {
          canvas,
          filesList: imageFiles,
          targetParameters: [
            {
              contentId: ChannelId + "-INDEX-_1024",
              fileType: "jpg",
              partition: partitionId,
              newWidth: 1024,
              newHeight: 768,
            },
            {
              contentId: ChannelId + "-INDEX-_512",
              fileType: "jpg",
              partition: partitionId,
              newWidth: 512,
              newHeight: 384,
            },
            {
              contentId: ChannelId + "-INDEX-_256",
              fileType: "jpg",
              partition: partitionId,
              newWidth: 256,
              newHeight: 256,
            },
          ],
        };
    
        scaleAndUploadImages(inputImageData, {
          okCallback: (clientDetailCode: number) => {
            console.log(
              "Feed: Image save done (" +
                clientDetailCode +
                "). Saving Channel data...",
            );
            setFileInputListAddChannel(undefined);
    
            updateTypesBestEffort(props.auth, partitionId, ChannelId);
    
            saveChannelChannel(inputData, {
              okCallback: (clientDetailCode: number) => {
                console.log("okCallback: " + clientDetailCode);
                handleOk();
                return;
              },
              errorCallback: (clientDetailCode: number, errorMessage: string) => {
                handleError(errorMessage + " (" + clientDetailCode + ")");
                return;
              },
              authCallback: {
                isSignedIn: () => props.auth.isSignedIn(),
                bearer: () => props.auth.bearer,
                sequence: () => props.auth.sequence,
                setBearerAndSequence: (bearer: string, sequence: number) => {
                  props.auth.setBearer(bearer);
                  props.auth.setSequence(sequence);
                },
              },
            });
          },
          errorCallback: (clientDetailCode: number, errorMessage: string) => {
            handleError(errorMessage + " (" + clientDetailCode + ")");
            return;
          },
          authCallback: {
            isSignedIn: () => props.auth.isSignedIn(),
            bearer: () => props.auth?.bearer,
            sequence: () => props.auth?.sequence,
            userId: () => props.auth.userId,
            setBearerAndSequence: (bearer: string, sequence: number) => {
              props.auth.setBearer(bearer);
              props.auth.setSequence(sequence);
            },
          },
        });
        */
    };
    useEffect(() => {
        console.log("useEffect: addChannelPrompt = " + addChannelPrompt);
        validateAddChannelPrompt(addChannelPrompt);
        return () => { };
    }, [addChannelPrompt]);
    useEffect(() => {
        console.log("useEffect: addChannelText = " + addChannelText);
        validateAddChannelText(addChannelText);
        return () => { };
    }, [addChannelText]);
    useEffect(() => {
        console.log("useEffect: addChannelTitle = " + addChannelTitle);
        validateAddChannelTitle(addChannelTitle);
        return () => { };
    }, [addChannelTitle]);
    useEffect(() => {
        console.log("useEffect: addChannelSubtitle = " + addChannelSubtitle);
        validateAddChannelSubtitle(addChannelSubtitle);
        return () => { };
    }, [addChannelSubtitle]);
    useEffect(() => {
        console.log("useEffect: mount - addChannelSavedOk = " + addChannelSavedOk);
        if (addChannelSavedOk) {
            setTimeout(() => {
                setAddChannelSavedOk(false);
                setAddChannelTitle("");
                setAddChannelSubtitle("");
                setAddChannelText("");
                setAddChannelImageUrl(undefined);
                if (props.reload) {
                    props.reload();
                }
            }, 4000);
        }
        return () => {
            console.log("useEffect: unmount - addChannelSavedOk");
        };
    }, [addChannelSavedOk]);
    useEffect(() => {
        console.log("useEffect: mount - addChannelSaveError = " + addChannelSaveError);
        if (addChannelSaveError) {
            setTimeout(() => {
                setAddChannelSaveError(undefined);
                setAddChannelSavedOk(false);
                setAddChannelTitle("");
                setAddChannelSubtitle("");
                setAddChannelText("");
                setAddChannelImageUrl(undefined);
                if (props.reload) {
                    props.reload();
                }
            }, 4000);
        }
        return () => {
            console.log("useEffect: unmount - addChannelSaveError");
        };
    }, [addChannelSaveError]);
    useEffect(() => {
        console.log("useEffect: mount - addChannelImageDataUrl = " + !!addChannelImageDataUrl);
        return () => {
            console.log("useEffect: unmount - addChannelImageDataUrl");
        };
    }, [addChannelImageDataUrl]);
    const addChannelImageFilenameChanged = () => {
        var _a, _b, _c;
        console.log("addChannelImageFilenameChanged");
        if ((_a = hiddenFileInputAddChannel.current) === null || _a === void 0 ? void 0 : _a.files) {
            setFileInputListAddChannel((_b = hiddenFileInputAddChannel.current) === null || _b === void 0 ? void 0 : _b.files);
            const filename = (_c = hiddenFileInputAddChannel.current) === null || _c === void 0 ? void 0 : _c.files[0];
            console.log("addChannelImageFilenameChanged: filename = " + filename.name);
            console.log("addChannelImageFilenameChanged: file type = " + filename.type);
            console.log("addChannelImageFilenameChanged: file size = " + filename.size);
            if (filename.type != "image/jpeg" &&
                filename.type != "image/png" &&
                filename.type != "video/mp4" &&
                filename.type != "video/quicktime") {
                // TODO: ERROR
                return;
            }
            const mediaUrl = URL.createObjectURL(filename);
            console.log("addChannelImageFilenameChanged: mediaUrl = " + mediaUrl);
            if (filename.type == "video/mp4") {
                console.log("MP4: set video preview URL = " + mediaUrl);
                //        setVideoPreviewUrl(mediaUrl);
                // TODO
                return;
            }
            if (filename.type == "video/quicktime") {
                console.log("MP4: set video preview URL = " + mediaUrl);
                //        setVideoPreviewUrl(mediaUrl);
                // TODO
                return;
            }
            setAddChannelImageUrl(mediaUrl);
            const img = new Image();
            img.onload = () => {
                console.log("New add Channel image loaded.");
                if (canvasRef.current) {
                    const canvas = canvasRef.current;
                    scaleDownImage(canvas, img, 1024, 1024);
                    const imageDataUrl = canvas.toDataURL("image/png", 1);
                    console.log("imageDataUrl = " + imageDataUrl);
                    console.log("imageDataUrl len = " + imageDataUrl.length);
                    setAddChannelImageDataUrl(imageDataUrl);
                }
            };
            img.onerror = (error) => {
                console.error("Unable to load image : " + mediaUrl + " - " + error);
                // TODO: ERROR
            };
            img.crossOrigin = "anonymous";
            img.src = mediaUrl;
        }
    };
    const addChannelMediaClicker = () => {
        console.log("addChannelMediaClicker: add Channel - upload - click");
        if (hiddenFileInputAddChannel.current) {
            console.log("addChannelMediaClicker: add Channel - upload - click: Click: hiddenFileInput");
            hiddenFileInputAddChannel.current.click();
        }
        else {
            console.error("addChannelMediaClicker: missing hidden file input reference");
        }
    };
    const newChannelId = () => {
        const timeIndex = 9999999999999 - new Date().getTime();
        const newChannelId = timeIndex + "-" + uuidv4();
        return newChannelId;
    };
    const addChannelSavingProgress = (_jsxs("div", Object.assign({ style: { width: "100%" } }, { children: [_jsx("br", {}), _jsx(Typography, Object.assign({ variant: "h6", color: "primary" }, { children: "Saving..." })), _jsx(LinearProgress, { color: "primary" })] })));
    const addChannelCardContent = (_jsxs(Stack, Object.assign({ style: { padding: "10px" } }, { children: [_jsx(TextField, { fullWidth: true, id: "outlined-multiline-static-title", label: "Title", color: "black", size: "small", onChange: (event) => {
                    const value = event.target.value;
                    console.log("add Channel title onChange - " + value);
                    setAddChannelTitle(value);
                }, error: !!addChannelHelperTitle, helperText: addChannelHelperTitle, disabled: addChannelSaving, value: addChannelTitle, sx: { marginBottom: "8px" } }, "cards.addChannel.cardcontent.textfield.title"), _jsx(TextField, { fullWidth: true, id: "outlined-multiline-static-subtitle", label: "Subtitle", color: "black", size: "small", onChange: (event) => {
                    const value = event.target.value;
                    console.log("add Channel subtitle onChange - " + value);
                    setAddChannelSubtitle(value);
                }, error: !!addChannelHelperSubtitle, helperText: addChannelHelperSubtitle, disabled: addChannelSaving, value: addChannelSubtitle, sx: { marginBottom: "8px" } }, "cards.addChannel.cardcontent.textfield.subtitle"), _jsx(TextField, { fullWidth: true, id: "outlined-multiline-static", label: "Describe your channel here...", multiline: true, rows: 2, color: "black", onChange: (event) => {
                    const value = event.target.value;
                    console.log("add channel text onChange - " + value);
                    setAddChannelText(value);
                }, error: !!addChannelHelperText, helperText: addChannelHelperText, disabled: addChannelSaving, value: addChannelText, sx: { marginBottom: "3px" } }, "cards.addChannel.cardcontent.textfield"), _jsxs(FormControl, { children: [_jsx(FormLabel, Object.assign({ id: "radio-buttons-group-channel-access" }, { children: "Access" })), _jsxs(RadioGroup, Object.assign({ "aria-labelledby": "radio-buttons-group-channel-access-label", defaultValue: "public", name: "radio-buttons-group-channel-access-name", onChange: (event) => {
                            const value = event.target.value;
                            console.log("setAccess: " + value);
                            setAccess(value);
                        } }, { children: [_jsx(FormControlLabel, { value: "public", control: _jsx(Radio, {}), label: "Public - Anyone can view channel content." }), _jsx(FormControlLabel, { value: "private", control: _jsx(Radio, {}), label: "Private - Channel viewers must be approved by channel owner." })] }))] }), access == "private" && (_jsx(TextField, { fullWidth: true, id: "outlined-multiline-static-memberprompt", label: "What question do you want to ask potential members?", multiline: true, rows: 2, color: "black", onChange: (event) => {
                    const value = event.target.value;
                    console.log("add channel prompt onChange - " + value);
                    setAddChannelPrompt(value);
                }, error: !!addChannelHelperPrompt, helperText: addChannelHelperPrompt, disabled: addChannelSaving, value: addChannelPrompt, sx: { marginBottom: "3px" } }, "cards.addChannel.cardcontent.memberprompt")), _jsxs(Stack, Object.assign({ direction: "row", width: "100%", sx: { display: "flex", justifyContent: "center", marginTop: "5px" }, spacing: 1 }, { children: [_jsx("input", { type: "file", accept: "image/jpeg,image/png", ref: hiddenFileInputAddChannel, style: { display: "none" }, onChange: addChannelImageFilenameChanged, multiple: true }, "add-Channel-hidden-file-input"), _jsx("canvas", { ref: canvasRef, style: { display: "none" } }, "canvas.newChannelcard"), addChannelSaving && addChannelSavingProgress, !addChannelSaving && (_jsx(Button, Object.assign({ variant: addChannelImageUrl ? "outlined" : "contained", endIcon: _jsx(AddAPhoto, {}), onClick: () => {
                            addChannelMediaClicker();
                        } }, { children: "ADD PHOTO" }), "cards.addChannel.button.photo"))] }), "add-Channel-stack-2-" + addChannelSavedOk), addChannelSaveError && (_jsx(Card, Object.assign({ elevation: 0, sx: { backgroundColor: "#FFEEEE", padding: "4", marginTop: "0.5em" }, color: "text.error" }, { children: _jsxs(CardContent, { children: [_jsx(Typography, Object.assign({ variant: "h6", component: "div", color: "error", gutterBottom: true }, { children: "SAVE FAILED" })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: addChannelSaveError }))] }, "add-Channel-error-card-content") }), "add-Channel-error-card")), addChannelSavedOk && (_jsx(Card, Object.assign({ elevation: 0, sx: { backgroundColor: "#EEFFEE", padding: "4", marginTop: "0.5em" }, color: "text.error" }, { children: _jsxs(CardContent, { children: [_jsx(Typography, Object.assign({ variant: "h6", component: "div", color: "EEFFEE", gutterBottom: true }, { children: "PUBLISHED" })), _jsx(Typography, Object.assign({ variant: "body2", color: "black" }, { children: "Your channel has been published." }))] }, "add-Channel-saved-ok-card-content") }), "add-Channel-saved-ok-card" + addChannelSavedOk))] }), "add-Channel-stack-card-" + addChannelSavedOk + addChannelSaveError));
    const addChannelCardActions = (_jsx(CardActions, Object.assign({ sx: { display: "flex", justifyContent: "center", padding: 0, margin: 0 } }, { children: !addChannelSaving && (_jsx(Button, Object.assign({ endIcon: _jsx(IosShare, {}), variant: "contained", onClick: () => {
                doPublish();
            }, sx: { margin: "0.5em" }, disabled: !addChannelTitle ||
                !!addChannelHelperTitle ||
                !!addChannelHelperSubtitle ||
                !!addChannelHelperText ||
                (!addChannelText &&
                    (!fileInputListAddChannel || fileInputListAddChannel.length <= 0)) }, { children: "Publish" }), "cards.addChannel.button.publish")) }), "add-Channel-card-actions"));
    const addChannelCard = (_jsx(ContentCard, { keyStr: "content.card.addChannel." + addChannelSavedOk + addChannelImageDataUrl, title: "New Channel", backgroundColor: getTheme().palette.violet.contrastText, textColor: getTheme().palette.black.main, childComponent: addChannelCardContent, actions: addChannelCardActions, mediaUrl: addChannelImageDataUrl, maxWidth: 0.5 }, "content.card.addChannel." + addChannelSavedOk + addChannelImageDataUrl));
    return addChannelCard;
};
