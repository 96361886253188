import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { loadChannelDataFromURL } from "../DataUtils";
import { ContentCard } from "./ContentCard";
import getTheme from "../Theme";
import { Avatar, Box, Button, ButtonGroup, CardActions, CardHeader, Divider, FormControl, FormControlLabel, LinearProgress, Radio, RadioGroup, Stack, Typography, } from "@mui/material";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import AddReactionIcon from "@mui/icons-material/AddReaction";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EmojiPicker from "emoji-picker-react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { addComment, listComments, } from "../Store/CommentsStore";
import { useProfileAuthStore } from "../Store/ProfileStore";
import { getDeltaTimestampString } from "../DateTimeUtils";
import { CommentElement } from "./CommentElement";
import { EMOJI_COMMENTS } from "../CharUtils";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { NewCommentRow } from "./NewCommentRow";
import { DEFAULT_REASON, submitReport } from "../Store/ReportStore";
import { authUrl } from "../UrlUrils";
export const getEntryTimestampString = (entry) => {
    console.log("entry: " + entry);
    const entryTime = +entry.substring(0, entry.indexOf("-"));
    const timeIndex = 9999999999999 - entryTime;
    console.log("entry timestamp: " + timeIndex);
    return getDeltaTimestampString(timeIndex);
};
export const PostCard = (props) => {
    const [postTitle, setPostTitle] = useState("");
    const [postSubtitle, setPostSubtitle] = useState("");
    const [postText, setPostText] = useState("");
    const [postOwnerId, setPostOwnerId] = useState();
    const [postUrl, setPostUrl] = useState("");
    const [mediaUrl, setMediaUrl] = useState("");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [enableEditButtons, setEnableEditButtons] = useState(props.enableEditButtons);
    const [deleteClicked, setDeleteClicked] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [postDateString, setPostDateString] = useState("");
    const [errorMessage, setErrorMessage] = useState();
    const [loading, setLoading] = useState(false);
    const [comments, setComments] = useState();
    const [emojis, setEmojis] = useState();
    const [emojiPickerVisible, setEmojiPickerVisible] = useState(false);
    const [myEmoji, setMyEmoji] = useState();
    const [maxEmojisShown, setMaxEmojisShown] = useState(5);
    const [multipleFileCount, setMultipleFileCount] = useState(0);
    // TODO: Respect permissions
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [commentsAllowed, setCommentsAllowed] = useState(true);
    const [reportVisible, setReportVisible] = useState(false);
    const [reportReason, setReportReason] = useState();
    const [reportSaving, setReportSaving] = useState(false);
    const [reportSavedOK, setReportSavedOK] = useState(false);
    const [reportSaveError, setReportSaveError] = useState(undefined);
    const [menuVisible, setMenuVisible] = useState(false);
    const navigate = useNavigate();
    const auth = useProfileAuthStore((state) => state);
    const loadComments = () => {
        const callbacks = {
            okCallback: (clientDetailCode, comments) => {
                console.log("loadComments: " + JSON.stringify(comments));
                setLoading(false);
                setComments(comments);
            },
            errorCallback: (clientDetailCode, message) => {
                console.error("loadComments: " + message);
                setErrorMessage(message ? message : "Unable to load comments.");
                setLoading(false);
            },
        };
        if (!postOwnerId) {
            console.error("Attempted to list comments before post owner ID is set.");
            return;
        }
        setLoading(true);
        listComments(props.channelOwnerId, props.channelId, postOwnerId, props.postId, callbacks, auth);
    };
    const handleAddFirstEmojiClick = () => {
        setEmojiPickerVisible(true);
    };
    const handleEmojiClick = (emoji) => {
        console.log("handleEmojiClick: " + emoji);
        if (EMOJI_COMMENTS == emoji) {
            if (!window.location.href.includes("PostView")) {
                navigateToPostView();
            }
        }
        else {
            const callbacks = {
                okCallback: function (clientDetailCode) {
                    console.log("Emoji saved OK : " + clientDetailCode);
                    loadComments();
                },
                errorCallback: function (clientDetailCode, message) {
                    console.error(message + " " + clientDetailCode);
                    setErrorMessage(message);
                    setLoading(false);
                },
            };
            if (!postOwnerId) {
                console.error("Unable to add comment while postOwnerId is not set.");
                return;
            }
            addComment(props.channelOwnerId, props.channelId, postOwnerId, props.postId, undefined, emoji, callbacks, auth);
        }
    };
    const reportPost = () => {
        console.log("reportPost: " + reportReason);
        if (!postOwnerId || !auth.userId || !reportReason) {
            setReportVisible(false);
            return;
        }
        setReportSaving(true);
        submitReport({
            channelOwnerId: props.channelOwnerId,
            channelId: props.channelId,
            postOwnerId: postOwnerId,
            postId: props.postId,
            reportedByUserId: auth.userId,
            reportedByDisplayName: auth.displayName,
            reason: reportReason,
        }, {
            okCallback: (clientDetailCode) => {
                console.error("submitReport: ok - " + clientDetailCode);
                setReportReason(undefined);
                setReportSaving(false);
                setReportSavedOK(true);
            },
            errorCallback: (clientDetailCode, message) => {
                setReportReason(undefined);
                setReportSaving(false);
                setReportSavedOK(false);
                const errorMessage = message ? message : "Unknown failure";
                console.error("submitReport: error = " + errorMessage);
                setReportSaveError(errorMessage + " (" + clientDetailCode + ")");
            },
        }, auth);
    };
    useEffect(() => {
        if (reportSaveError) {
            setTimeout(() => {
                setReportSaveError(undefined);
                setReportVisible(false);
            }, 4000);
        }
        return () => { };
    }, [reportSaveError]);
    useEffect(() => {
        if (reportSavedOK) {
            setTimeout(() => {
                setReportSavedOK(false);
                setReportVisible(false);
            }, 4000);
        }
        return () => { };
    }, [reportSavedOK]);
    useEffect(() => {
        const retVal = () => { };
        const emojiCounts = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const emojiCountsMap = {};
        if (comments && comments.entries && comments.entries.length > 0) {
            console.log("useEffect: comments");
            comments.entries.forEach((entry) => {
                if (entry.text) {
                    const emoji = EMOJI_COMMENTS;
                    if (!emojiCountsMap[emoji]) {
                        emojiCountsMap[emoji] = 1;
                    }
                    else {
                        ++emojiCountsMap[emoji];
                    }
                }
                else if (entry.emoji) {
                    const emoji = entry.emoji;
                    if (!emojiCountsMap[emoji]) {
                        emojiCountsMap[emoji] = 1;
                    }
                    else {
                        ++emojiCountsMap[emoji];
                    }
                    if (entry.commentByUserId == auth.userId) {
                        setMyEmoji(emoji);
                    }
                }
            });
            console.log("emojiCountMap: " + JSON.stringify(emojiCountsMap));
            Object.keys(emojiCountsMap).forEach((key) => {
                const emojiEntry = {
                    emoji: key,
                    count: emojiCountsMap[key],
                };
                emojiCounts.push(emojiEntry);
            });
            emojiCounts.sort((a, b) => {
                if (a.emoji == EMOJI_COMMENTS) {
                    return -1;
                }
                else if (b.emoji == EMOJI_COMMENTS) {
                    return 1;
                }
                return a.count > b.count ? -1 : a.count < b.count ? 1 : 0;
            });
            setEmojis(emojiCounts);
        }
        else {
            setEmojis([]);
        }
        return retVal;
    }, [comments]);
    useEffect(() => {
        console.log("Post Owner ID: " + postOwnerId);
        if (postOwnerId) {
            loadComments();
        }
        return () => { };
    }, [postOwnerId]);
    useEffect(() => {
        console.log("useEffect: mount - postUrl");
        const url = props.basePath + "/" + props.postJson;
        if (!postUrl) {
            setPostUrl(url);
        }
        else {
            console.log("loading channel data from URL...");
            setLoading(true);
            loadChannelDataFromURL(postUrl, {
                okCallback: (clientDetailCode, data) => {
                    console.log("okCallback: post data = " + data + " - " + clientDetailCode);
                    setPostDateString(getEntryTimestampString(props.postJson));
                    try {
                        const dataObj = JSON.parse(data);
                        console.log("Post Data: " + JSON.stringify(dataObj));
                        if (dataObj === null || dataObj === void 0 ? void 0 : dataObj.title) {
                            setPostTitle(dataObj.title);
                        }
                        if (dataObj === null || dataObj === void 0 ? void 0 : dataObj.subtitle) {
                            setPostSubtitle(dataObj.subtitle);
                        }
                        if (dataObj === null || dataObj === void 0 ? void 0 : dataObj.text) {
                            setPostText(dataObj.text);
                        }
                        if (dataObj === null || dataObj === void 0 ? void 0 : dataObj.postOwnerId) {
                            setPostOwnerId(dataObj.postOwnerId);
                        }
                        if (dataObj === null || dataObj === void 0 ? void 0 : dataObj.hasImage) {
                            if (!(dataObj === null || dataObj === void 0 ? void 0 : dataObj.hasMultipleFiles)) {
                                if (props.maxResolution) {
                                    setMediaUrl(authUrl(url.replace(".json", "_1024.jpg"), auth));
                                }
                                else {
                                    setMediaUrl(authUrl(url.replace(".json", "_512.jpg"), auth));
                                }
                            }
                            else {
                                setMultipleFileCount(dataObj === null || dataObj === void 0 ? void 0 : dataObj.multipleFileCount);
                                const imageUrl = postUrl.replace(".json", "_" + (dataObj === null || dataObj === void 0 ? void 0 : dataObj.multipleFileCount) + "_512.jpg");
                                if (!props.expandPostContent) {
                                    setMediaUrl(authUrl(imageUrl, auth));
                                }
                            }
                        }
                    }
                    catch (err) {
                        console.error("post data parse error: " + err);
                        setErrorMessage(err.message);
                    }
                    finally {
                        setLoading(false);
                    }
                },
                errorCallback: (clientDetailCode, errorMessage) => {
                    console.error("post data error: " + errorMessage + " (" + clientDetailCode + ")");
                    if (errorMessage && errorMessage.includes("NoSuchKey")) {
                        setErrorMessage("No post exists with that key. It may have been deleted. Check the URL and try again.");
                    }
                    else {
                        setErrorMessage(errorMessage + " (" + clientDetailCode + ")");
                    }
                    setLoading(false);
                },
            });
        }
        return () => {
            console.log("useEffect: unmount - postUrl");
        };
    }, [postUrl]);
    useEffect(() => {
        console.log("useEffect: media url = " + mediaUrl);
        return () => { };
    }, [mediaUrl]);
    const commentElements = [];
    const navigateToPostView = () => {
        navigate("/PostView?channelOwnerId=" +
            props.channelOwnerId +
            "&channelId=" +
            props.channelId +
            "&postId=" +
            props.postId);
    };
    if (reportVisible) {
        const actions = (_jsxs(CardActions, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: [_jsx(Button, Object.assign({ variant: "contained", onClick: () => {
                        reportPost();
                    } }, { children: "Submit" })), _jsx(Button, Object.assign({ variant: "outlined", onClick: () => {
                        setReportVisible(false);
                        setReportReason(undefined);
                    } }, { children: "Cancel" }))] })));
        if (reportSaveError) {
            commentElements.push(_jsx(ContentCard, { keyStr: "report.save.error." + props.postId, title: "ERROR", childComponent: _jsx(Typography, Object.assign({ variant: "body1", sx: { padding: "20px" } }, { children: reportSaveError })), textColor: getTheme().palette.black.main, backgroundColor: "#FFEEEE", maxWidth: 0.9, maxMediaWidth: props.maxMediaWidth, maxMediaHeight: props.maxMediaHeight }, "report.save.error." + props.postId));
        }
        else if (reportSavedOK) {
            commentElements.push(_jsx(ContentCard, { keyStr: "report.saved.ok." + props.postId, title: "SUCCESS", childComponent: _jsx(Typography, Object.assign({ variant: "body1", sx: { padding: "20px" } }, { children: "Your report has been submitted." })), textColor: getTheme().palette.black.main, backgroundColor: "#EEFFEE", maxWidth: 0.9, maxMediaWidth: props.maxMediaWidth, maxMediaHeight: props.maxMediaHeight }, "report.saved.ok." + props.postId));
        }
        else if (reportSaving) {
            console.log("render: reportSaving");
            commentElements.push(_jsx(LinearProgress, { sx: { width: "100%" } }, "report.saving.progress." + props.postId));
        }
        else {
            commentElements.push(_jsx(Stack, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: _jsx(ContentCard, { title: "REPORT POST", keyStr: "content.card.post.confirm.report." + props.postId, childComponent: _jsxs(Stack, Object.assign({ direction: "column", sx: { display: "flex", justifyContent: "center" } }, { children: [_jsx(Typography, Object.assign({ sx: { display: "flex", justifyContent: "center" }, variant: "body1" }, { children: "Why do you want to report this post?" })), _jsx(FormControl, { children: _jsxs(RadioGroup, Object.assign({ "aria-labelledby": "report-buttons-group-label", defaultValue: DEFAULT_REASON, name: "radio-buttons-group", sx: { margin: "auto" }, onChange: (event) => {
                                        setReportReason(event.target.value);
                                    } }, { children: [_jsx(FormControlLabel, { value: "ads", control: _jsx(Radio, {}), label: "Post advertises for a business." }), _jsx(FormControlLabel, { value: "fraud", control: _jsx(Radio, {}), label: "Post is fraudulent." }), _jsx(FormControlLabel, { value: "hate", control: _jsx(Radio, {}), label: "Post advocates for violence or hate." }), _jsx(FormControlLabel, { value: "illegal", control: _jsx(Radio, {}), label: "Post is illegal." }), _jsx(FormControlLabel, { value: "inappropriate", control: _jsx(Radio, {}), label: "Post is inappropriate." }), _jsx(FormControlLabel, { value: "other", control: _jsx(Radio, {}), label: "Other" })] })) })] }), "report.sub.stack.menu." + props.postId), actions: actions, backgroundColor: "#FFFFFF", textColor: "#333333" }, "content.card.post.confirm.report." + props.postId) }), "report.menu.stack." + props.postId));
        }
    }
    if (props.expandPostContent && commentsAllowed && postOwnerId && !loading) {
        commentElements.push(_jsx(NewCommentRow, { channelOwnerId: props.channelOwnerId, channelId: props.channelId, postOwnerId: postOwnerId, postId: props.postId, reload: () => {
                loadComments();
            }, keyStr: "newcommentrow." + props.postId }, "newcommentrow." + props.postId));
    }
    if (props.expandPostContent &&
        comments &&
        comments.entries &&
        comments.entries.length > 0) {
        comments.entries.forEach((comment) => {
            commentElements.push(_jsx(CommentElement, { comment: comment, keyStr: "comment.element." + comment.commentId, reload: () => {
                    loadComments();
                } }, "comment.element." + comment.commentId));
        });
    }
    let text = postText;
    const buttonSxProps = {
        minWidth: 0,
        minHeight: 0,
        padding: 0,
        margin: 0,
        height: "36px",
        marginBottom: "1px",
    };
    const emojiButtonsList = [];
    const imageList = [];
    let imageQuilt;
    if (multipleFileCount > 0) {
        const resolution = "512";
        for (let imageUrlIndex = 1; imageUrlIndex <= multipleFileCount; ++imageUrlIndex) {
            const imageUrl = postUrl.replace(".json", "_" + imageUrlIndex + "_" + resolution + ".jpg");
            imageList.unshift(imageUrl);
        }
        if (props.expandPostContent) {
            imageQuilt = (_jsx(Stack, Object.assign({ direction: "row", flexWrap: "wrap", display: "flex", justifyContent: "center", sx: { padding: 0, margin: 0 } }, { children: imageList.map((item) => (_jsx(Button, Object.assign({ onClick: () => {
                        window.location.href = item.replace("_" + resolution + ".jpg", "_1024.jpg");
                    } }, { children: _jsx(Box, Object.assign({ sx: { padding: 0, margin: 0 } }, { children: _jsx("img", { src: item, style: {
                                maxHeight: props.maxMediaHeight,
                                objectFit: "contain",
                                margin: 0,
                                padding: 0,
                            }, onLoad: (event) => {
                                console.log("PostCard.cardMedia.onLoad: " + mediaUrl);
                                if (event.target instanceof HTMLImageElement) {
                                    const img = event.target;
                                    if (props.maxMediaHeight != undefined &&
                                        img.height > props.maxMediaHeight) {
                                        img.height = props.maxMediaHeight;
                                        console.log("reset img.height = " + img.height);
                                    }
                                    if (img.width > window.innerWidth) {
                                        img.width = window.innerWidth - 10;
                                    }
                                }
                            } }) })) }), "button.image." + item))) }), "imagequilt.stack." + props.postId));
        }
    }
    if (emojis) {
        for (let emojiListIndex = 0; emojiListIndex < maxEmojisShown; ++emojiListIndex) {
            if (emojis.length > emojiListIndex) {
                emojiButtonsList.push(_jsxs(Button, Object.assign({ size: "small", sx: buttonSxProps, variant: emojis[emojiListIndex].emoji == myEmoji ? "outlined" : "text", onClick: () => {
                        handleEmojiClick(emojis[emojiListIndex].emoji);
                    } }, { children: [_jsxs(Typography, Object.assign({ color: "#000000", fontSize: "28px" }, { children: ["\u00A0", emojis[emojiListIndex].emoji] })), _jsxs(Typography, Object.assign({ fontSize: "16px", sx: { lineHeight: 2.1 } }, { children: ["\u00A0", emojis[emojiListIndex].count, "\u00A0\u00A0"] }))] }), "emoji.button." + props.postId + "." + emojiListIndex));
            }
        }
    }
    const menuButtonsList = [];
    menuButtonsList.push(_jsx(Button, Object.assign({ size: "medium", onClick: () => {
            setMenuVisible(false);
            setReportVisible(true);
        } }, { children: "REPORT" }), "report.button.post.report." + props.postId));
    menuButtonsList.push(_jsx(Button, Object.assign({ size: "medium", onClick: () => {
            setMenuVisible(false);
            setReportVisible(false);
            setReportReason(DEFAULT_REASON);
        } }, { children: "HIDE" }), "report.button.post.hide." + props.postId));
    const menuButtons = (_jsx(Stack, Object.assign({ direction: "row", display: "flex", justifyContent: "right" }, { children: _jsx(ButtonGroup, Object.assign({ orientation: "vertical", variant: "outlined", sx: { margin: "10px" } }, { children: menuButtonsList }), menuVisible + ".post.menu." + props.postId) })));
    let firstEmojiButtonPadding = "0";
    let postMenuMarginTop = "-3px";
    if (comments && comments.entries && comments.entries.length <= 0) {
        if (props.expandPostContent) {
            firstEmojiButtonPadding = "5px";
            postMenuMarginTop = "-5px";
        }
        else {
            firstEmojiButtonPadding = "5px";
            postMenuMarginTop = "-6px";
        }
    }
    const childComponent = (_jsxs(Stack, Object.assign({ direction: "column" }, { children: [props.expandPostContent && (_jsxs(Helmet, { children: [_jsx("title", { children: "MediaShare.link Post" }), _jsx("meta", { property: "og:url", content: window.location.href }), _jsx("meta", { property: "og:type", content: "article" }), _jsx("meta", { property: "og:title", content: "MediaShare.link Post" }), _jsx("meta", { property: "og:description", content: text }), _jsx("meta", { property: "og:image", content: mediaUrl
                            ? authUrl(mediaUrl, auth)
                            : multipleFileCount > 1
                                ? authUrl(imageList[0], auth)
                                : "https://www.mediashare.link/img/logo.svg" })] })), !props.expandPostContent && multipleFileCount > 1 && !loading && (_jsx(Stack, Object.assign({ direction: "row", sx: { display: "flex", justifyContent: "center" } }, { children: _jsx(Avatar, Object.assign({ sx: {
                        cursor: "pointer",
                        marginTop: "-50px",
                        marginBottom: "10px",
                        backgroundColor: "#333333",
                        color: "#FFFFFF",
                    }, onClick: () => {
                        navigateToPostView();
                    } }, { children: _jsx(PhotoLibraryIcon, {}) }), "post.overlay.album.icon." + props.postId) }))), loading && _jsx(LinearProgress, { sx: { width: "100%" } }), imageQuilt, !!text && (_jsx(Typography, Object.assign({ onClick: props.onClick, whiteSpace: "pre-line", variant: "body1", color: getTheme().palette.black.main, sx: {
                    textAlign: "left",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    wordBreak: "break-word",
                    cursor: props.onClick != null ? "pointer" : undefined,
                } }, { children: text ? text : undefined }), props.keyStr + "-typography")), _jsx(Divider, { orientation: "horizontal", flexItem: true }), menuVisible && menuButtons, !menuVisible && !reportVisible && (_jsxs(Stack, Object.assign({ direction: "row", sx: {
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    paddingTop: "1px",
                    paddingBottom: 0,
                    verticalAlign: "middle",
                    display: "flex",
                } }, { children: [emojis && emojis.length > 0 && emojiButtonsList, emojis && emojis.length > maxEmojisShown && (_jsx(Button, Object.assign({ size: "small", sx: buttonSxProps, onClick: () => {
                            setMaxEmojisShown(100);
                        } }, { children: _jsx(Typography, Object.assign({ color: "violet", fontSize: "28px", sx: { lineHeight: 2.1 } }, { children: _jsx(MoreHorizIcon, {}) })) }))), !loading && commentsAllowed && (_jsxs(Button, Object.assign({ size: "small", sx: {
                            minWidth: 0,
                            margin: 0,
                            marginTop: "-1px",
                            padding: firstEmojiButtonPadding,
                        }, onClick: () => {
                            handleAddFirstEmojiClick();
                        } }, { children: [_jsx(AddReactionIcon, { sx: {
                                    paddingLeft: "7px",
                                    paddingTop: "0",
                                    lineHeight: 2.2,
                                    fontSize: "24px",
                                    color: "#AAAAAA",
                                } }), _jsx(ExpandMoreIcon, { sx: {
                                    paddingLeft: "0",
                                    paddingTop: "0",
                                    lineHeight: 2.2,
                                    fontSize: "20px",
                                    color: "#AAAAAA",
                                } })] }))), !menuVisible && (_jsxs(Stack, Object.assign({ sx: {
                            flexGrow: 3,
                            textAlign: "right",
                            alignItems: "right",
                            justifyContent: "right",
                        } }, { children: [_jsx(Typography, Object.assign({ fontSize: "small", sx: { lineHeight: 1.9, paddingRight: "2px", marginTop: "-5px" }, color: "#aaaaaa" }, { children: postDateString })), _jsx(Typography, Object.assign({ fontSize: "large", sx: {
                                    lineHeight: 0.5,
                                    paddingRight: "2px",
                                    marginTop: postMenuMarginTop,
                                    cursor: "pointer",
                                    color: getTheme().palette.violet.main,
                                }, color: "violet", onClick: () => {
                                    console.log("PostCard.onClick");
                                    setMenuVisible(true);
                                } }, { children: "..." }))] })))] }), "emoji.row.stack." + props.postId)), enableEditButtons &&
                !deleteClicked &&
                !deleting &&
                enableEditButtons && (_jsx(Stack, Object.assign({ direction: "row", sx: {
                    padding: "5px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                } }, { children: _jsx(Button, Object.assign({ variant: "contained", sx: { margin: "5px" }, onClick: () => {
                        setDeleteClicked(true);
                    } }, { children: "Delete" })) }))), enableEditButtons && deleteClicked && !deleting && (_jsxs(Stack, Object.assign({ direction: "column" }, { children: [_jsx("hr", { style: { width: "100%" } }), _jsx(Typography, Object.assign({ variant: "h6", color: getTheme().palette.violet.main }, { children: "DELETE POST" })), _jsx(Typography, Object.assign({ variant: "body1", color: getTheme().palette.black.main }, { children: "Are you sure?" })), _jsxs(Stack, Object.assign({ direction: "row", sx: {
                            padding: "5px",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                        } }, { children: [_jsx(Button, Object.assign({ variant: "contained", sx: { margin: "5px" }, onClick: () => {
                                    if (props.deletePost) {
                                        props.deletePost(props.basePath, props.postJson);
                                        setDeleteClicked(false);
                                        setDeleting(true);
                                    }
                                } }, { children: "Yes" })), _jsx(Button, Object.assign({ variant: "outlined", sx: { margin: "5px" }, onClick: () => {
                                    setDeleteClicked(false);
                                } }, { children: "No" }))] }), props.keyStr + ".button.stack.row")] }))), enableEditButtons && deleting && _jsx(LinearProgress, {}), commentElements && commentElements.length > 0 && (_jsxs(Stack, Object.assign({ direction: "column" }, { children: [_jsx(Divider, {}), _jsx("div", { style: { margin: 0, padding: 0, marginTop: "2px" } }), commentElements, _jsx("div", { style: { margin: 0, padding: 0, marginBottom: "5px" } })] }), "comments.stack.column"))] }), postUrl + "stack.column"));
    text = undefined;
    if (emojiPickerVisible) {
        return (_jsxs(Stack, Object.assign({ direction: "column" }, { children: [_jsx(Stack, Object.assign({ direction: "row", sx: { width: "100%", textAlign: "right", display: "table-cell" } }, { children: _jsx(Button, Object.assign({ onClick: () => {
                            setEmojiPickerVisible(false);
                        }, size: "small", sx: { minWidth: "24px" } }, { children: _jsx(Typography, Object.assign({ variant: "h6", sx: { width: "auto", textAlign: "right" } }, { children: "X" })) })) }), "postcard.emoji.picker.substack." + props.postId), _jsx(EmojiPicker, { onEmojiClick: (emojiData) => {
                        console.log("onEmojiClick: " + emojiData.emoji);
                        setEmojiPickerVisible(false);
                        handleEmojiClick(emojiData.emoji);
                    } })] }), "postcard.emoji.picker.stack." + props.postId));
    }
    if (errorMessage) {
        const errorChildComponent = (_jsx(Typography, Object.assign({ variant: "body1" }, { children: errorMessage })));
        return (_jsx(ContentCard, { keyStr: props.keyStr + "/" + props.postJson + mediaUrl, title: "ERROR", childComponent: errorChildComponent, textColor: getTheme().palette.black.main, backgroundColor: "#FFEEEE", maxWidth: props.maxWidth, maxMediaWidth: props.maxMediaWidth, maxMediaHeight: props.maxMediaHeight }, props.keyStr + "/" + props.postJson + mediaUrl));
    }
    let header;
    if (postTitle || postSubtitle) {
        header = (_jsx(CardHeader, { title: postTitle, subheader: postSubtitle, sx: {
                margin: 0,
                padding: 0,
                marginLeft: "10px",
                marginRight: "10px",
                cursor: props.onClick ? "pointer" : "auto",
            }, onClick: props.onClick }));
    }
    const elm = (_jsx(ContentCard, { keyStr: props.keyStr + "/" + props.postJson + mediaUrl, title: "", header: header, childComponent: childComponent, mediaUrl: !errorMessage && mediaUrl ? authUrl(mediaUrl, auth) : undefined, textColor: getTheme().palette.black.main, backgroundColor: "#FFFFFF", onMediaClick: props.onClick, maxWidth: props.maxWidth, maxMediaWidth: props.maxMediaWidth, maxMediaHeight: props.maxMediaHeight }, props.keyStr + "/" + props.postJson + mediaUrl));
    return elm;
};
