import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Heading from "./Heading";
import Footer from "./Footer";
import { Box, Card, CardContent, Container, LinearProgress, Stack, ThemeProvider, Typography, } from "@mui/material";
import getTheme from "./Theme";
import { getLayoutInfo } from "./LayoutInfo";
import { useEffect, useState } from "react";
import { getChannelId, getContentURL, } from "./ContentAPI";
import { cacheBuster } from "./ImageUtils";
import { DATA_UTIL_CLIENT_ERROR_LOAD_DATA_FAILED, listPosts, loadChannelData, } from "./DataUtils";
import { ContentCardStacks } from "./components/ContentCardStacks";
import { ContentCard } from "./components/ContentCard";
import { PostCard } from "./components/PostCard";
import { useProfileAuthStore } from "./Store/ProfileStore";
import { useNavigate } from "react-router-dom";
import { LoadMoreButton } from "./components/LoadMoreButton";
import { UserAvatar } from "./components/UserAvatar";
import { useFriendsStore } from "./Store/FriendStore";
import { Helmet } from "react-helmet-async";
const channelsImg = "./img/channels_512.jpg";
export function ChannelView() {
    const layout = getLayoutInfo();
    const [displayName, setDisplayName] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [channelOwnerId, setChannelOwnerId] = useState();
    const [channelId, setChannelId] = useState();
    const [channelTitle, setChannelTitle] = useState();
    const [channelDescription, setChannelDescription] = useState();
    const [channelImageUrl, setChannelImageUrl] = useState();
    const [postIds, setPostIds] = useState([]);
    const [hasMorePosts, setHasMorePosts] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [permissionsRequiresFriend, setPermissionsRequiresFriend] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [permissionsAllowsComments, setPermissionsAllowsComments] = useState(false);
    const [needsToBeFriends, setNeedsToBeFriends] = useState(false);
    const navigate = useNavigate();
    const auth = useProfileAuthStore((state) => state);
    const friends = useFriendsStore((state) => state);
    const path = window.location.search
        ? window.location.search
        : window.location.href;
    console.log("mount: path = " + path);
    const profileName = path.substring(path.lastIndexOf("@") + 1, path.length);
    console.log("mount: profileName = " + profileName);
    useEffect(() => {
        console.log("mount - displayName");
        if (!path.includes("@")) {
            setErrorMessage("Channel name must contain '@' character. Please check the URL.");
        }
        else {
            if (!displayName && !errorMessage && profileName) {
                setLoading(true);
                getChannelId((ok, status, response) => {
                    var _a;
                    if (!ok || !(status >= 200 && status < 300)) {
                        let errorMessage = "Unable to find Channel with name " + profileName + ".";
                        if (response === null || response === void 0 ? void 0 : response.error) {
                            errorMessage = response === null || response === void 0 ? void 0 : response.error;
                        }
                        if (response === null || response === void 0 ? void 0 : response.clientDetailCode) {
                            errorMessage += " (" + (response === null || response === void 0 ? void 0 : response.clientDetailCode) + ")";
                        }
                        console.error(status + " " + errorMessage);
                        setErrorMessage(errorMessage);
                        setLoading(false);
                        return;
                    }
                    else if (!response || !response.channelId) {
                        console.error(status + " " + errorMessage);
                        setErrorMessage(errorMessage);
                        setLoading(false);
                        return;
                    }
                    else if (!response.channelId || !response.channelId.trim()) {
                        console.error("Channel ID missing from response.");
                        setErrorMessage("Invalid response received from server.");
                        setLoading(false);
                        return;
                    }
                    else if (!response.channelName || !response.channelName.trim()) {
                        console.error("Channel name missing from response.");
                        setErrorMessage("Invalid response received from server.");
                        setLoading(false);
                        return;
                    }
                    else {
                        setLoading(false);
                        setErrorMessage(undefined);
                        setChannelId(response === null || response === void 0 ? void 0 : response.channelId);
                        setChannelOwnerId(response === null || response === void 0 ? void 0 : response.channelOwnerId);
                        setDisplayName(response === null || response === void 0 ? void 0 : response.channelName);
                        const postIds = [];
                        (_a = response === null || response === void 0 ? void 0 : response.postIds) === null || _a === void 0 ? void 0 : _a.forEach((entry) => {
                            postIds.push(entry);
                        });
                        console.log("Post IDs = " + JSON.stringify(postIds));
                        setPostIds(postIds);
                        console.log("Has More Posts = " + (response === null || response === void 0 ? void 0 : response.hasMorePosts));
                        setHasMorePosts((response === null || response === void 0 ? void 0 : response.hasMorePosts) ? true : false);
                    }
                }, profileName);
            }
        }
        return () => { };
    }, [displayName]);
    useEffect(() => {
        console.log("useEffect - ownerId=%s, channelId=%s", channelOwnerId, channelId);
        const callbacks = {
            okCallback: (clientDetailCode, data) => {
                var _a, _b, _c;
                console.log("Channel Data = " + data);
                try {
                    const dataObj = JSON.parse(data);
                    const dataTitle = dataObj.title;
                    const dataDescription = dataObj.description;
                    const channelImageUrl = getContentURL() +
                        "/u/" +
                        channelOwnerId +
                        "/" +
                        channelId +
                        "/feedImage_512.jpg";
                    if ((_a = dataObj.permissions) === null || _a === void 0 ? void 0 : _a.permissionsRequiresFriend) {
                        setPermissionsRequiresFriend(true);
                    }
                    else {
                        setPermissionsRequiresFriend(false);
                        setLoading(false);
                    }
                    if ((_b = dataObj.permissions) === null || _b === void 0 ? void 0 : _b.permissionsAllowsComments) {
                        setPermissionsAllowsComments(true);
                    }
                    else {
                        setPermissionsAllowsComments(false);
                    }
                    if (!auth || !auth.isSignedIn()) {
                        if ((_c = dataObj.permissions) === null || _c === void 0 ? void 0 : _c.permissionsRequiresSignIn) {
                            // TODO: Save context
                            navigate("/Login");
                            return;
                        }
                    }
                    setChannelImageUrl(channelImageUrl);
                    setChannelTitle(dataTitle);
                    setChannelDescription(dataDescription);
                }
                catch (err) {
                    setErrorMessage("ERROR: " + err);
                    setLoading(false);
                }
            },
            errorCallback: (clientDetailCode, errorMessage) => {
                console.error(clientDetailCode + " " + errorMessage);
                if (clientDetailCode == DATA_UTIL_CLIENT_ERROR_LOAD_DATA_FAILED) {
                    setErrorMessage("No posts found on this channel. Please check the URL.");
                }
                else {
                    setErrorMessage(errorMessage);
                }
                setLoading(false);
            },
        };
        if (channelId && channelOwnerId) {
            setLoading(true);
            loadChannelData("u", channelOwnerId, channelId, "feedData", "json", cacheBuster(), callbacks);
        }
        return () => {
            console.log("unmount - channelId");
        };
    }, [channelOwnerId, channelId]);
    useEffect(() => {
        console.log("useEffect - permissionsRequiresFriend = " + permissionsRequiresFriend);
        if (channelOwnerId && permissionsRequiresFriend) {
            if (!auth.isSignedIn()) {
                // Not logged in
                navigate("/Login");
            }
            else if (auth.userId == channelOwnerId) {
                setLoading(false);
            }
            else if (!friends.ok &&
                !friends.done &&
                !friends.loading &&
                !friends.error) {
                // Friends not loaded
                console.log("Loading friends of " + channelOwnerId + "...");
                friends.load(channelOwnerId, auth).then(() => {
                    if (friends.ok && friends.friends) {
                        let found = false;
                        friends.friends.forEach((entry) => {
                            if (entry.userId == auth.userId) {
                                found = true;
                                console.log("Found match - friends");
                            }
                        });
                        if (!found) {
                            console.log("NOT found match - NOT friends");
                            setNeedsToBeFriends(true);
                            setLoading(false);
                        }
                        else {
                            setLoading(false);
                        }
                    }
                    else {
                        setLoading(false);
                        throw new Error("Friends loaded ok, but invalid state.");
                    }
                });
            }
        }
        return () => {
            console.log("unmount - permissionsRequiresFriend");
        };
    }, [friends, permissionsRequiresFriend, channelOwnerId]);
    //let adScript;
    //if (postIds && channelOwnerId && channelId) {
    /*
      adScript = <div>
  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3680816698886183"
     crossOrigin="anonymous"></script>
  <ins className="adsbygoogle"
     style={{display:"block"}}
     data-ad-format="fluid"
     data-ad-layout-key="-6t+ed+2i-1n-4w"
     data-ad-client="ca-pub-3680816698886183"
     data-ad-slot="7412012817"></ins>
  <script>
     (adsbygoogle = window.adsbygoogle || []).push({});
  </script></div>; */
    //<Typography variant="body1">ADVERTISEMENT</Typography>
    /*
      adScript = (
        <div
          style={{ width: "auto", textAlign: "center" }}
          id="channel-view-display-adslot"
        >
          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3680816698886183"
            crossOrigin="anonymous"
          ></script>
          <ins
            className="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client="ca-pub-3680816698886183"
            data-ad-slot="7886826975"
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
          <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
        </div>
      );
      */
    //}
    const loadMorePosts = () => {
        console.log("loadMorePosts");
        if (!postIds ||
            postIds.length <= 0 ||
            !hasMorePosts ||
            !channelOwnerId ||
            !channelId) {
            console.log("loadMorePosts: No posts exist or owner/channel not set");
            return;
        }
        if (!auth || !auth.isSignedIn() || !auth.userId) {
            console.log("not signed in");
            navigate("/Login");
            return;
        }
        const lastPostId = postIds.at(postIds.length - 1);
        console.log("loadMorePosts: lastPostId = " + lastPostId);
        setLoadingMore(true);
        listPosts({
            operation: "listPosts",
            channelOwnerId: channelOwnerId,
            channelId: channelId,
            postId: lastPostId,
        }, {
            okCallback: (clientDetailCode, data) => {
                console.log("ChannelView: loadMorePosts: " + data);
                setLoadingMore(false);
                const results = JSON.parse(data);
                if (results === null || results === void 0 ? void 0 : results.postIds) {
                    results.postIds.forEach((entry) => {
                        if (postIds.includes(entry)) {
                            console.error("ChannelView: loadMorePosts: DUPLICATE: " + entry);
                        }
                        else {
                            console.log("ChannelView: loadMorePosts: ADDING: " + entry);
                            postIds.push(entry);
                        }
                    });
                }
                if (results === null || results === void 0 ? void 0 : results.hasMorePosts) {
                    setHasMorePosts(true);
                }
                else {
                    setHasMorePosts(false);
                }
            },
            errorCallback: (clientDetailCode, errorMessage) => {
                // TODO
                console.log("Feed: listPosts: ERROR = " + errorMessage);
                setLoadingMore(false);
            },
        }, {
            isSignedIn: () => auth.isSignedIn(),
            bearer: () => auth.bearer,
            sequence: () => auth.sequence,
            setBearerAndSequence: (bearer, sequence) => {
                auth.setBearer(bearer);
                auth.setSequence(sequence);
            },
        });
    };
    const cardsList = [];
    let userAvatar = undefined;
    if (channelTitle && channelDescription && channelOwnerId) {
        userAvatar = (_jsx(UserAvatar, { userId: channelOwnerId, keyStr: channelOwnerId + ".avatar", onUserClicked: () => {
                navigate("/ProfileView?userId=" + channelOwnerId);
            }, userName: "", userNameVisible: false }, channelOwnerId + ".avatar"));
        const channelCard = (_jsx(ContentCard, { keyStr: "channel.card", title: channelTitle, titleAvatar: userAvatar, backgroundColor: getTheme().palette.violet.contrastText, textColor: getTheme().palette.black.main, text: channelDescription, mediaUrl: channelImageUrl, mediaFallbackUrl: channelsImg, maxWidth: 0.5, maxMediaHeight: window.innerHeight - 70 }, "channel.card"));
        cardsList.push(channelCard);
    }
    if (channelOwnerId && channelId && postIds && postIds.length > 0) {
        postIds.forEach((entry) => {
            const basePath = getContentURL() + "/u/" + channelOwnerId + "/" + channelId;
            const postId = entry.substring(0, entry.lastIndexOf("."));
            cardsList.push(_jsx(PostCard, { channelOwnerId: channelOwnerId, channelId: channelId, postId: postId, basePath: basePath, postJson: entry, enableEditButtons: false, expandPostContent: false, keyStr: entry + ".card", maxMediaHeight: window.innerHeight - 70, onClick: () => {
                    console.log("post.onClick: channelId %s postId %s", channelId, entry);
                    navigate("/PostView?channelOwnerId=" +
                        channelOwnerId +
                        "&channelId=" +
                        channelId +
                        "&postId=" +
                        entry.substring(0, entry.lastIndexOf(".")));
                } }, entry + ".card"));
        });
        /*
        if (adScript) {
          console.log("included ad in-feed");
          cardsList.push(
            <ContentCard
              title=""
              key={"channelview.ad.infeed.contentcard." + channelId}
              keyStr={"channelview.ad.infeed.contentcard." + channelId}
              textColor={getTheme().palette.primary.main}
              backgroundColor="#FFFFFF"
              childComponent={adScript}
            />,
          );
        }
          */
    }
    const columnStack = _jsx(ContentCardStacks, { cards: cardsList }, "cards.stack");
    const content = (_jsxs(Stack, Object.assign({ sx: { padding: 0, marging: 0 }, spacing: 0 }, { children: [_jsx(Typography, Object.assign({ variant: "h5", color: "primary", sx: { textAlign: "center", width: "100%" } }, { children: displayName ? "@" + displayName : "" })), errorMessage && (_jsx(Card, Object.assign({ elevation: 10, sx: {
                    backgroundColor: "#FFEEEE",
                    padding: layout.cardPadding,
                    margin: layout.cardMargin,
                    alignContent: "center",
                    textAlign: "center",
                } }, { children: _jsxs(CardContent, Object.assign({ sx: {
                        padding: layout.cardContentPadding,
                        margin: layout.cardContentMargin,
                    } }, { children: [_jsx(Typography, Object.assign({ variant: "h6", color: "error" }, { children: "ERROR" })), _jsx(Typography, Object.assign({ variant: "body1", sx: {
                                textAlign: "left",
                                paddingLeft: layout.cardContentPadding,
                                paddingRight: layout.cardContentPadding,
                            } }, { children: errorMessage }))] }), "channelview.content.cardcontent.error") }), "channelview.content.card.error")), columnStack] }), "channelview.content.stack"));
    const needsFriendContent = (_jsx(Box, Object.assign({ sx: { width: "100%", justifyContent: "center", display: "flex" } }, { children: _jsxs(Stack, Object.assign({ direction: "column", sx: { padding: "5px" } }, { children: [_jsx(Typography, Object.assign({ variant: "h6" }, { children: "Not Allowed" })), _jsx(Typography, Object.assign({ variant: "body1" }, { children: "Channel may only be viewed by friends of the owner." })), userAvatar] })) })));
    const output = (_jsx("div", Object.assign({ style: { width: "100%", padding: 0, margin: 0 }, id: "outer" }, { children: _jsxs(ThemeProvider, Object.assign({ theme: getTheme() }, { children: [_jsxs(Helmet, { children: [_jsx("title", { children: channelTitle }), _jsx("meta", { property: "og:url", content: window.location.href }), _jsx("meta", { property: "og:title", content: channelTitle }), _jsx("meta", { property: "og:description", content: channelDescription }), _jsx("meta", { property: "og:image", content: channelImageUrl }), _jsx("meta", { property: "og:type", content: "article" }), _jsx("meta", { name: "description", content: channelDescription }), _jsx("link", { rel: "canonical", href: window.location.href })] }), _jsx(Heading, {}), _jsxs(Container, Object.assign({ sx: {
                        backgroundColor: "#FFFFFF",
                        width: "100%",
                        padding: 0,
                        margin: 0,
                    }, maxWidth: false }, { children: [loading && _jsx(LinearProgress, { sx: { width: "100%" } }), needsToBeFriends && needsFriendContent, !needsToBeFriends && !loading && content, !needsToBeFriends && !loading && hasMorePosts && (_jsx("div", Object.assign({ style: {
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                            } }, { children: _jsx(LoadMoreButton, { onClick: loadMorePosts, loading: loadingMore }) })))] })), _jsx(Footer, {})] })) }), "channelview.div"));
    return output;
}
