import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Card, CardContent, LinearProgress, Stack, TextField, Typography, } from "@mui/material";
import { IosShare } from "@mui/icons-material";
import { UserAvatar } from "./UserAvatar";
import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useProfileAuthStore } from "../Store/ProfileStore";
import { ContentCard } from "./ContentCard";
import { addComment } from "../Store/CommentsStore";
const CardContentNoPadding = styled(CardContent)(`
    padding: 0;
    &:last-child {
      padding-top: 0;
      padding-bottom: 0;
    }
  `);
export const NewCommentRow = (props) => {
    const [textFieldFocused, setTextFieldFocused] = useState();
    const [textContent, setTextContent] = useState();
    const [textError, setTextError] = useState(undefined);
    const [saveError, setSaveError] = useState(undefined);
    const [saving, setSaving] = useState(false);
    const [savedOk, setSavedOk] = useState(false);
    const auth = useProfileAuthStore((state) => state);
    const textRef = useRef(null);
    const navigate = useNavigate();
    const validateText = (textString) => {
        setSaveError(undefined);
        if (!textString || textString.trim().length <= 0) {
            setTextError(undefined);
            return;
        }
        if (textString.includes("<")) {
            setTextError("Comment contains invalid character '<'");
            return;
        }
        setTextError(undefined);
    };
    const doPublish = () => {
        console.log("NewCommentRow.doPublish");
        setSaveError(undefined);
        setSaving(true);
        const callbacks = {
            okCallback: function (clientDetailCode) {
                console.log("Comment saved OK : " + clientDetailCode);
                setSaving(false);
                setSavedOk(true);
            },
            errorCallback: function (clientDetailCode, message) {
                console.error(message + " " + clientDetailCode);
                setSaving(false);
                if (message) {
                    setSaveError(message + " " + clientDetailCode);
                }
                else {
                    setSaveError("Unknown failure. Please try again.");
                }
            },
        };
        if (!props.postOwnerId) {
            console.error("Unable to add comment while postOwnerId is not set.");
            return;
        }
        addComment(props.channelOwnerId, props.channelId, props.postOwnerId, props.postId, textContent, undefined, callbacks, auth);
    };
    useEffect(() => {
        const retVal = () => { };
        if (savedOk) {
            setTimeout(() => {
                setSavedOk(false);
                setTextContent(undefined);
                props.reload();
            }, 2500);
        }
        return retVal;
    }, [savedOk]);
    useEffect(() => {
        const retVal = () => { };
        if (saveError) {
            setTimeout(() => {
                setSaveError(undefined);
            }, 4500);
        }
        return retVal;
    }, [saveError]);
    if (!auth || !auth.isSignedIn() || !auth.userId || !auth.displayName) {
        return _jsx("span", {});
    }
    if (saving) {
        return _jsx(LinearProgress, { sx: { width: "100%" } });
    }
    const commentByUserId = auth.userId;
    let commentByDisplayName = auth.displayName;
    if (!commentByDisplayName) {
        commentByDisplayName = "Unknown";
    }
    const cardStyle = {
        width: "100%",
        borderRadius: "15px",
        marginTop: "7px",
        marginBottom: "6px",
        marginRight: "6px",
        padding: 0,
        backgroundColor: "#F0F0FF",
    };
    if (savedOk) {
        return (_jsx(Stack, Object.assign({ sx: { marginTop: "10px" } }, { children: _jsx(ContentCard, { keyStr: "saved.comment.ok.content.card." + props.postId, title: "SUCCESS", textColor: "#333333", backgroundColor: "#EEFFEE", childComponent: _jsx(Typography, Object.assign({ variant: "body1", sx: { display: "flex", justifyContent: "center", margin: "20px" } }, { children: "Comment has been published." })), maxWidth: 0.9 }, "saved.comment.ok.content.card." + props.postId) }), "stack." + "saved.comment.ok.content.card." + props.postId));
    }
    if (saveError) {
        return (_jsx(Stack, Object.assign({ sx: { marginTop: "10px" } }, { children: _jsx(ContentCard, { keyStr: "saved.comment.failed.content.card." + props.postId, title: "FAILED", textColor: "#333333", backgroundColor: "#FFEEEE", childComponent: _jsxs(Typography, Object.assign({ variant: "body1", sx: { display: "flex", justifyContent: "center", margin: "20px" } }, { children: ["Unable to save comment. ", saveError] })), maxWidth: 0.9 }, "saved.comment.failed.content.card." + props.postId) }), "stack.saved.comment.failed.content.card." + props.postId));
    }
    return (_jsxs(Stack, Object.assign({ direction: "row" }, { children: [_jsx(UserAvatar, { userId: commentByUserId, userName: commentByDisplayName, userNameVisible: false, keyStr: props.keyStr +
                    "avatar.newcomment." +
                    commentByDisplayName +
                    commentByUserId +
                    props.postId +
                    props.channelId, onUserClicked: () => {
                    navigate("/ProfileView?userId=" + commentByUserId);
                } }, props.keyStr +
                "avatar.newcomment." +
                commentByDisplayName +
                commentByUserId +
                props.postId +
                props.channelId), _jsx(Card, Object.assign({ sx: cardStyle, variant: "outlined" }, { children: _jsx(CardContentNoPadding, Object.assign({ sx: {
                        padding: 0,
                        margin: 0,
                        display: "table-block",
                        verticalAlign: "middle",
                    } }, { children: _jsxs(Stack, Object.assign({ direction: "column", width: "auto", sx: { margin: 0, padding: 0 } }, { children: [_jsx(TextField, { ref: textRef, variant: "outlined", label: "Write your comment here...", size: "small", sx: {
                                    width: "auto",
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                    marginTop: textFieldFocused || (textContent && textContent.length > 0)
                                        ? "8px"
                                        : 0,
                                    marginBottom: textFieldFocused || (textContent && textContent.length > 0)
                                        ? "8px"
                                        : 0,
                                }, color: "black", value: textContent, onChange: (event) => {
                                    const value = event.target.value;
                                    console.log("text onChange - " + value);
                                    setTextContent(value);
                                    validateText(value);
                                    setSavedOk(false);
                                }, onBlur: () => {
                                    console.log("onBlur");
                                    setTextFieldFocused(false);
                                }, onFocus: () => {
                                    console.log("onFocus");
                                    setTextFieldFocused(true);
                                }, error: !!textError, helperText: textError, disabled: saving, multiline: true }, "cards.comment.cardcontent.textfield"), (textFieldFocused || (textContent && textContent.length > 0)) && (_jsx(Button, Object.assign({ variant: "contained", endIcon: _jsx(IosShare, {}), disabled: !!textError || !textContent || textContent.length <= 0, onClick: () => {
                                    doPublish();
                                } }, { children: "Publish Comment" })))] }), "comment.cardcontent.stack." + props.keyStr) }), "comment.cardcontent." + props.keyStr) }), "comment.card." + props.keyStr)] }), props.keyStr +
        "stack.avatar.newcomment." +
        commentByDisplayName +
        commentByUserId +
        props.postId +
        props.channelId));
};
