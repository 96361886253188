export const getDeltaTimestampString = (timestamp) => {
    const now = new Date().getTime();
    const deltaMillis = now - timestamp;
    const deltaSecs = Math.trunc(deltaMillis / 1000);
    //  console.log("entry timestamp delta: " + deltaSecs);
    const deltaMins = Math.trunc(deltaSecs / 60);
    //  console.log("entry timestamp delta mins: " + deltaMins);
    const deltaHours = Math.trunc(deltaMins / 60);
    //  console.log("entry timestamp delta hours: " + deltaHours);
    const deltaDays = Math.trunc(deltaHours / 24);
    //  console.log("entry timestamp delta days: " + deltaDays);
    const deltaMonths = Math.trunc(deltaDays / 30);
    //  console.log("entry timestamp delta months: " + deltaMonths);
    let deltaStr = "";
    if (deltaMonths == 1) {
        deltaStr = "1 month";
    }
    else if (deltaMonths > 1) {
        deltaStr = Math.trunc(deltaMonths) + " months";
    }
    else if (deltaDays == 1) {
        deltaStr = Math.trunc(deltaDays) + " day";
    }
    else if (deltaDays > 1) {
        deltaStr = Math.trunc(deltaDays) + " days";
    }
    else if (deltaHours == 1) {
        deltaStr = Math.trunc(deltaHours) + " hour";
    }
    else if (deltaHours > 1) {
        deltaStr = Math.trunc(deltaHours) + " hours";
    }
    else if (deltaMins == 1) {
        deltaStr = Math.trunc(deltaMins) + " min";
    }
    else if (deltaMins > 1) {
        deltaStr = Math.trunc(deltaMins) + " mins";
    }
    else {
        deltaStr = "now";
    }
    //  console.log("timestamp delta string = " + deltaStr);
    return deltaStr;
};
