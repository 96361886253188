import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { PublicContent } from "./Public";
import Login from "./Auth/Login";
import AuthCallback from "./Auth/AuthCallback";
import { Home } from "./Home";
import { MUIReference } from "./MUIReference";
import { LogoutPage } from "./LogoutPage";
import { useProfileAuthStore } from "./Store/ProfileStore";
import { About } from "./About";
import { Admin } from "./Admin";
import { Feed } from "./Feed";
import { Channels } from "./Channels";
import { Messaging } from "./Messaging";
import { Friends } from "./Friends";
import { Terms } from "./Terms";
import { ChannelView } from "./ChannelView";
import { ContentAdmin } from "./ContentAdmin";
import { PostView } from "./PostView";
import { ProfileView } from "./ProfileView";
import { ProfileSettings } from "./ProfileSettings";
import { HelmetProvider } from "react-helmet-async";
export default function App() {
    const bearer = useProfileAuthStore((state) => state.bearer);
    let mainContent = _jsx(PublicContent, {});
    if (bearer) {
        mainContent = _jsx(Home, {});
    }
    if (window.location.pathname.startsWith("/@")) {
        mainContent = _jsx(ChannelView, {});
    }
    return (_jsx(HelmetProvider, { children: _jsx(BrowserRouter, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/authcb", element: _jsx(AuthCallback, {}) }), _jsx(Route, { path: "/authcb.html", element: _jsx(AuthCallback, {}) }), _jsx(Route, { path: "/About", element: _jsx(About, {}) }), _jsx(Route, { path: "/Admin", element: _jsx(Admin, {}) }), _jsx(Route, { path: "/Terms", element: _jsx(Terms, {}) }), _jsx(Route, { path: "/Feed", element: _jsx(Feed, {}) }), _jsx(Route, { path: "/Channels", element: _jsx(Channels, {}) }), _jsx(Route, { path: "/Messaging", element: _jsx(Messaging, {}) }), _jsx(Route, { path: "/Friends", element: _jsx(Friends, {}) }), _jsx(Route, { path: "/login", element: _jsx(Login, {}) }), _jsx(Route, { path: "/Logout", element: _jsx(LogoutPage, {}) }), _jsx(Route, { path: "/muiref", element: _jsx(MUIReference, {}) }), _jsx(Route, { path: "/ChannelView", element: _jsx(ChannelView, {}) }), _jsx(Route, { path: "/ProfileView", element: _jsx(ProfileView, {}) }), _jsx(Route, { path: "/Profile", element: _jsx(ProfileSettings, {}) }), _jsx(Route, { path: "/ContentAdmin", element: _jsx(ContentAdmin, {}) }), _jsx(Route, { path: "/PostView", element: _jsx(PostView, {}) }), _jsxs(Route, Object.assign({ path: "/", element: mainContent }, { children: [_jsx(Route, { index: true, element: mainContent }), _jsx(Route, { path: "*", element: mainContent })] }))] }) }) }));
}
