import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Heading from "./Heading";
import Footer from "./Footer";
import { Card, CardContent, Container, Link, Stack, ThemeProvider, Typography, } from "@mui/material";
import EngineeringIcon from "@mui/icons-material/Engineering";
import getTheme from "./Theme";
import { getLayoutInfo } from "./LayoutInfo";
const backgroundImg = "./img/wordcloud_512.jpg";
const videoURL = "./background.mov";
export function About() {
    const layout = getLayoutInfo();
    const backgroundVideo = (_jsxs("video", Object.assign({ id: "background-video", loop: true, autoPlay: true, style: {
            textAlign: "center",
            verticalAlign: "middle",
            width: layout.videoWidth,
            display: "inline-flex",
        }, poster: backgroundImg }, { children: [_jsx("source", { src: videoURL, type: "video/quicktime" }), _jsx("source", { src: videoURL, type: "video/mp4" }), _jsx("source", { src: videoURL, type: "video/ogg" })] }), "background-video"));
    const content = (_jsxs(Stack, Object.assign({ sx: { padding: "2em" }, spacing: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "h5", color: "primary", sx: { textAlign: "center", width: "100%" } }, { children: "About Us" })), _jsx(Card, Object.assign({ elevation: 10, sx: {
                    backgroundColor: "#FFFFFF",
                    padding: layout.cardPadding,
                    margin: layout.cardMargin,
                    alignContent: "center",
                    textAlign: "center",
                } }, { children: _jsx(CardContent, Object.assign({ sx: {
                        padding: layout.cardContentPadding,
                        margin: layout.cardContentMargin,
                    } }, { children: _jsxs(Stack, Object.assign({ spacing: 1, alignItems: "center", sx: { textAlign: "center" } }, { children: [_jsx(Typography, Object.assign({ variant: "h6", color: "primary" }, { children: "Pardon our mess" })), _jsx(EngineeringIcon, { fontSize: "large" }), _jsx(Typography, Object.assign({ variant: "body1", sx: {
                                    textAlign: "left",
                                    paddingLeft: layout.cardContentPadding,
                                    paddingRight: layout.cardContentPadding,
                                } }, { children: "Welcome to the MediaShare.link social media network. You may find some features are missing or coming soon. We appreciate your patience. New features will be added as they become available. We hope you enjoy the working capabilities of the site. The word cloud below represents our vision for our completed site. We appreciate you joining us on this journey. Thank you for your patience." })), _jsx("br", {}), _jsx(Typography, Object.assign({ variant: "h6", color: "primary" }, { children: "Welcome to the MediaShare.link social media network!" })), _jsx("br", {}), _jsx("br", {}), _jsx(Typography, Object.assign({ variant: "caption", color: "violet", fontSize: "medium" }, { children: "Our Vision" })), _jsx("div", Object.assign({ style: {
                                    width: "100%",
                                    height: "100%",
                                    display: "inline-block",
                                    textAlign: "center",
                                } }, { children: backgroundVideo })), _jsx("br", {}), _jsx("br", {}), _jsx(Typography, Object.assign({ variant: "h6" }, { children: "Our Policies" })), _jsx(Link, Object.assign({ href: "https://app.termly.io/policy-viewer/policy.html?policyUUID=4be1a4fa-e1a9-4c75-854d-365b3cbf93f1" }, { children: _jsx(Typography, Object.assign({ variant: "body2" }, { children: "Cookie Policy" })) })), _jsx(Link, Object.assign({ href: "https://app.termly.io/policy-viewer/policy.html?policyUUID=426bfff1-769a-4e7b-a7ff-23c76382b689" }, { children: _jsx(Typography, Object.assign({ variant: "body2" }, { children: "Terms of Use" })) })), _jsx(Link, Object.assign({ href: "https://app.termly.io/policy-viewer/policy.html?policyUUID=27991f50-d1d3-4aa9-a83a-41a121bc371c" }, { children: _jsx(Typography, Object.assign({ variant: "body2" }, { children: "Privacy Notice" })) })), _jsx(Link, Object.assign({ href: "https://app.termly.io/notify/27991f50-d1d3-4aa9-a83a-41a121bc371c" }, { children: _jsx(Typography, Object.assign({ variant: "body2" }, { children: "Data Subject Access Request (DSAR)" })) }))] })) })) }))] })));
    const output = (_jsx("div", Object.assign({ style: { width: "100%", padding: 0, margin: 0 }, id: "outer" }, { children: _jsxs(ThemeProvider, Object.assign({ theme: getTheme() }, { children: [_jsx(Heading, {}), _jsx(Container, Object.assign({ sx: { backgroundColor: "#FFFFFF", padding: 0, margin: 0 } }, { children: content })), _jsx(Footer, {})] })) })));
    return output;
}
