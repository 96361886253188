export class InvalidInputError extends Error {
    constructor(message, fieldName, fieldValue) {
        super(message);
        this.fieldName = fieldName;
        this.fieldValue = fieldValue;
    }
}
export const validateDisplayName = (input) => {
    const trimmed = input.trim();
    if (!trimmed) {
        throw new InvalidInputError("Display name cannot be empty.", "displayName", input);
    }
    const regExp = new RegExp("^[a-zA-Z]+[a-zA-Z0-9_-]*$");
    if (!trimmed.match(regExp)) {
        throw new InvalidInputError("Display name may only contain letters, numbers, underscore, or minus, and must start with a letter.", "displayName", trimmed);
    }
    if (trimmed.length < 3) {
        throw new InvalidInputError("Display name must be at least 3 characters long.", "displayName", trimmed);
    }
    if (trimmed.length > 50) {
        throw new InvalidInputError("Display name must be 50 characters or less in length.", "displayName", trimmed);
    }
    return;
};
export const validateEmailAddress = (input) => {
    const trimmed = input.trim();
    if (!trimmed) {
        throw new InvalidInputError("Email address cannot be empty.", "email", input);
    }
    const regExp = new RegExp("^.+@.+..+$");
    if (!trimmed.match(regExp)) {
        throw new InvalidInputError("Email address is not valid.", "email", trimmed);
    }
    return;
};
