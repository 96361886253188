import { getAPIEndpoint } from "./API";
const PATH = "content";
export function getUploadURL(_callback, userId, contentId, partitionId, fileType, bearer, sequence) {
    console.log("getUploadURL - userId = " +
        userId +
        ", contentId = " +
        contentId +
        ", partitionId = " +
        partitionId +
        ", fileType = " +
        fileType);
    const payload = {
        operation: "getUploadURL",
        contentId,
        fileType,
        partition: partitionId,
        userId: userId,
        sequence,
        //    getKeys,
    };
    const xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function () {
        if (xmlhttp.readyState == XMLHttpRequest.DONE) {
            // XMLHttpRequest.DONE == 4
            console.log("HTTP status: " + xmlhttp.status);
            const status = +xmlhttp.status;
            let response;
            if (xmlhttp.status >= 200 && xmlhttp.status < 300) {
                console.log("responseText: " + xmlhttp.responseText);
                if (xmlhttp.responseText) {
                    response = JSON.parse(xmlhttp.responseText);
                }
                _callback(true, status, response);
            }
            else {
                console.error("responseText: " + xmlhttp.responseText);
                if (xmlhttp.responseText) {
                    response = JSON.parse(xmlhttp.responseText);
                }
                _callback(false, status, response);
            }
        }
    };
    const url = getAPIEndpoint() + PATH;
    xmlhttp.open("POST", url, true);
    xmlhttp.setRequestHeader("Content-type", "application/json");
    if (bearer) {
        xmlhttp.setRequestHeader("Authorization", "Basic " + bearer);
    }
    xmlhttp.send(JSON.stringify(payload));
}
export const getContentURL = () => {
    const BASE_URL = "https://www.mediashare.link";
    if (window.location.href.includes("localhost:8080") ||
        window.location.href.includes("test.mediashare.link")) {
        return "https://test.mediashare.link";
    }
    else {
        return BASE_URL;
    }
};
export const getChannelViewBaseURL = () => {
    if (window.location.href.includes("localhost:8080")) {
        return "http://localhost:8080/ChannelView?/@";
    }
    else if (window.location.href.includes("test.mediashare.link")) {
        return "https://test.mediashare.link/@";
    }
    else {
        return "https://www.mediashare.link/@";
    }
};
export const getSiteBaseURL = () => {
    if (window.location.href.includes("localhost:8080")) {
        return "http://localhost:8080/";
    }
    else if (window.location.href.includes("test.mediashare.link")) {
        return "https://test.mediashare.link/";
    }
    else {
        return "https://www.mediashare.link/";
    }
};
export const isProd = () => {
    if (window.location.href.includes("localhost:8080") ||
        window.location.href.includes("test.mediashare.link")) {
        return false;
    }
    else {
        return true;
    }
};
export function getChannelId(_callback, channelName) {
    console.log("getChannelId - channelName = " + channelName);
    const payload = {
        operation: "getChannelId",
        channelName,
    };
    const xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function () {
        if (xmlhttp.readyState == XMLHttpRequest.DONE) {
            // XMLHttpRequest.DONE == 4
            console.log("getChannelId: HTTP status: " + xmlhttp.status);
            const status = +xmlhttp.status;
            let response;
            if (xmlhttp.status >= 200 && xmlhttp.status < 300) {
                console.log("getChannelId: responseText: " + xmlhttp.responseText);
                if (xmlhttp.responseText) {
                    response = JSON.parse(xmlhttp.responseText);
                }
                _callback(true, status, response);
            }
            else {
                console.error("getChannelId: responseText: " + xmlhttp.responseText);
                if (xmlhttp.responseText) {
                    response = JSON.parse(xmlhttp.responseText);
                }
                _callback(false, status, response);
            }
        }
    };
    const url = getAPIEndpoint() + "channelId";
    xmlhttp.open("POST", url, true);
    xmlhttp.setRequestHeader("Content-type", "application/json");
    xmlhttp.send(JSON.stringify(payload));
}
export function updateTypes(_callback, userId, contentId, partitionId, bearer, sequence) {
    console.log("updateTypes - userId = " +
        userId +
        ", contentId = " +
        contentId +
        ", partitionId = " +
        partitionId);
    const payload = {
        operation: "updateTypes",
        contentId,
        partition: partitionId,
        userId: userId,
        fileType: undefined,
        sequence,
    };
    const xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function () {
        if (xmlhttp.readyState == XMLHttpRequest.DONE) {
            // XMLHttpRequest.DONE == 4
            console.log("HTTP status: " + xmlhttp.status);
            const status = +xmlhttp.status;
            let response;
            if (xmlhttp.status >= 200 && xmlhttp.status < 300) {
                console.log("responseText: " + xmlhttp.responseText);
                if (xmlhttp.responseText) {
                    response = JSON.parse(xmlhttp.responseText);
                }
                _callback(true, status, response);
            }
            else {
                console.error("responseText: " + xmlhttp.responseText);
                if (xmlhttp.responseText) {
                    response = JSON.parse(xmlhttp.responseText);
                }
                _callback(false, status, response);
            }
        }
    };
    const url = getAPIEndpoint() + PATH;
    xmlhttp.open("POST", url, true);
    xmlhttp.setRequestHeader("Content-type", "application/json");
    if (bearer) {
        xmlhttp.setRequestHeader("Authorization", "Basic " + bearer);
    }
    xmlhttp.send(JSON.stringify(payload));
}
