import { jsx as _jsx } from "react/jsx-runtime";
import { Button, CardHeader } from "@mui/material";
import { ContentCard } from "./ContentCard";
import { UserAvatar } from "./UserAvatar";
import { useNavigate } from "react-router-dom";
export const FeedCardButton = (props) => {
    const navigate = useNavigate();
    return (_jsx(Button, Object.assign({ sx: { textTransform: "none", margin: 0, padding: 0, width: "100%" }, onClick: props.onClick }, { children: _jsx(ContentCard, { keyStr: props.userId + ".profile.feed.card", title: props.feedTitle, text: props.feedDescription ? props.feedDescription : undefined, backgroundColor: "#FFFFFF", textColor: "#333333", maxWidth: 0.5, mediaUrl: props.feedImageUrl, header: _jsx(CardHeader, { sx: { fontSize: "small", margin: 0, padding: 0 }, title: "Feed", titleTypographyProps: { variant: "body2" } }), titleAvatar: _jsx(UserAvatar, { userId: props.userId, userName: props.displayName, userNameVisible: false, onUserClicked: () => {
                    navigate("/ProfileView?userId=" + props.userId);
                }, keyStr: "feed.user.avatar" }, "feed.user.avatar") }, props.userId + ".profile.feed.card") })));
};
