var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { create } from "zustand";
import { sendAjaxRequest } from "../API";
var ClientDetailCode;
(function (ClientDetailCode) {
    ClientDetailCode[ClientDetailCode["PROFILE_SUCCESS"] = 1000] = "PROFILE_SUCCESS";
    ClientDetailCode[ClientDetailCode["MISSING_USER_ID"] = 30101] = "MISSING_USER_ID";
    ClientDetailCode[ClientDetailCode["PROFILE_GENERAL_ERROR"] = 7001] = "PROFILE_GENERAL_ERROR";
})(ClientDetailCode || (ClientDetailCode = {}));
export var ProfileOperation;
(function (ProfileOperation) {
    ProfileOperation[ProfileOperation["LoadProfile"] = 1] = "LoadProfile";
    ProfileOperation[ProfileOperation["SaveProfile"] = 2] = "SaveProfile";
    ProfileOperation[ProfileOperation["ResendCode"] = 3] = "ResendCode";
})(ProfileOperation || (ProfileOperation = {}));
export const deleteSession = () => {
    const date = new Date();
    // Set it expire in -1 days
    date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);
    // Set it
    document.cookie =
        "auth=; expires=" + date.toUTCString() + "; SameSite=Strict; path=/";
};
export const saveSession = (props) => {
    const str = JSON.stringify(props);
    const date = new Date();
    // Set it expire in 7 days
    date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);
    document.cookie =
        "auth=" +
            str +
            "; expires=" +
            date.toUTCString() +
            "; SameSite=Strict; path=/";
};
export const restoreSession = (authStore) => {
    var _a;
    try {
        const value = "; " + document.cookie;
        const parts = value.split("; auth=");
        if (parts.length == 2) {
            const value = (_a = parts.pop()) === null || _a === void 0 ? void 0 : _a.split(";").shift();
            if (value) {
                const restoreObj = JSON.parse(value);
                if (restoreObj &&
                    restoreObj.bearer &&
                    restoreObj.sequence &&
                    restoreObj.userId &&
                    restoreObj.userName) {
                    authStore.setSequence(restoreObj.sequence);
                    authStore.setUserId(restoreObj.userId);
                    authStore.setUserName(restoreObj.userName);
                    if (restoreObj.displayName) {
                        authStore.setDisplayName(restoreObj.displayName);
                    }
                    authStore.setBearer(restoreObj.bearer);
                    if (restoreObj.accountType) {
                        authStore.setAccountType(restoreObj.accountType);
                    }
                    console.log("Restored Session");
                    return true;
                }
            }
        }
    }
    catch (err) {
        console.error("restoreSession: " + err);
    }
    return false;
};
export const useProfileAuthStore = create()((set, get) => ({
    displayName: undefined,
    userId: undefined,
    userName: undefined,
    bearer: undefined,
    sequence: 0,
    accountType: undefined,
    isSignedIn: () => !!get().bearer,
    reset: () => {
        deleteSession();
        set({
            bearer: undefined,
            displayName: undefined,
            userId: undefined,
            sequence: 0,
        });
    },
    setAccountType: (newAccountType) => {
        set({ accountType: newAccountType });
        console.debug("new account type: " + get().accountType);
        saveSession(get());
    },
    setBearer: (newBearer) => {
        set({ bearer: newBearer });
        console.debug("new bearer: " + get().bearer);
        saveSession(get());
    },
    setDisplayName: (newDisplayName) => {
        set({ displayName: newDisplayName });
        console.debug("new display name: " + get().displayName);
        saveSession(get());
    },
    setSequence: (newSequence) => {
        set({ sequence: newSequence });
        console.debug("new sequence: " + get().sequence);
        saveSession(get());
    },
    setUserId: (newUserId) => {
        set({ userId: newUserId });
        console.debug("new user ID: " + get().userId);
        saveSession(get());
    },
    setUserName: (newUserName) => {
        set({ userName: newUserName });
        console.debug("new user name: " + get().userName);
        saveSession(get());
    },
    deleteSession,
    saveSession: () => {
        saveSession(get());
    },
}));
export const useProfileStore = create()((set, get) => ({
    operation: ProfileOperation.LoadProfile,
    start: false,
    ok: false,
    done: false,
    error: undefined,
    status: undefined,
    clientDetailCode: undefined,
    profile: undefined,
    reset: () => {
        set({
            start: false,
            ok: false,
            done: false,
            error: undefined,
            status: undefined,
            clientDetailCode: undefined,
            profile: undefined,
        });
    },
    loadProfile: (userId, auth) => {
        const state = get();
        if (!state.start && !state.done) {
            const payload = {
                operation: "load",
                profileUserId: userId,
                userId: auth.userId ? auth.userId : "",
                sequence: auth.sequence,
            };
            // Start Flow
            set({
                operation: ProfileOperation.LoadProfile,
                ok: false,
                done: false,
                start: true,
                error: undefined,
                status: undefined,
                clientDetailCode: undefined,
                profile: undefined,
            });
            sendAjaxRequest((ok, status, message) => {
                try {
                    //              console.log("OK: " + ok);
                    //              console.log("STATUS: " + status);
                    //              console.log("MESSAGE: " + JSON.stringify(message));
                    if (ok) {
                        const response = JSON.parse(message);
                        //                console.log("Server Response: " + JSON.stringify(response));
                        const profile = response.profile;
                        //                console.log("Server Profile: " + JSON.stringify(profile));
                        const bearer = response.bearer;
                        //                console.log("Server Bearer: " + bearer);
                        const sequence = response.sequence;
                        //                console.log("Server Sequence:" + sequence);
                        const clientDetailCode = response.clientDetailCode;
                        //                console.log("Client Detail Code:" + clientDetailCode);
                        // Update Auth for next call
                        auth.setBearer(bearer);
                        auth.setSequence(sequence);
                        set({
                            ok: true,
                            done: true,
                            start: false,
                            error: undefined,
                            status: status,
                            clientDetailCode: clientDetailCode,
                            profile: {
                                userName: profile === null || profile === void 0 ? void 0 : profile.userName,
                                userId: profile === null || profile === void 0 ? void 0 : profile.userId,
                                email: profile === null || profile === void 0 ? void 0 : profile.email,
                                displayName: profile === null || profile === void 0 ? void 0 : profile.displayName,
                                emailVerified: profile === null || profile === void 0 ? void 0 : profile.emailVerified,
                                terms: profile === null || profile === void 0 ? void 0 : profile.terms,
                                accountType: profile === null || profile === void 0 ? void 0 : profile.accountType,
                            },
                        });
                    }
                    else {
                        const response = JSON.parse(message);
                        let errorMessage = response.error;
                        console.error("ErrorMessage: " + errorMessage);
                        if (!errorMessage) {
                            errorMessage = "Unknown Failure";
                        }
                        // Clear Data
                        set({
                            ok: false,
                            done: true,
                            start: false,
                            error: errorMessage,
                            status: status,
                            clientDetailCode: response.clientDetailCode,
                            profile: undefined,
                        });
                    }
                }
                catch (error) {
                    // Clear Data
                    set({
                        ok: false,
                        done: true,
                        start: false,
                        error: error.message,
                        status: status,
                        clientDetailCode: 7001,
                        profile: undefined,
                    });
                }
            }, "profile", payload, auth.bearer);
        }
    },
    saveProfile: (auth, callbacks, displayName, email, code, termsAccepted) => {
        console.log("saveProfile");
        const state = get();
        const payload = {
            operation: "save",
            userId: auth.userId ? auth.userId : "",
            profileUserId: auth.userId ? auth.userId : "",
            sequence: auth.sequence,
            profile: {
                userId: auth.userId,
                userName: auth.userName,
                displayName: displayName,
                email: email,
                code: code,
                terms: termsAccepted ? 1 : undefined,
                accountType: undefined,
            },
        };
        // Start Flow
        set({
            operation: ProfileOperation.SaveProfile,
            ok: false,
            done: false,
            start: true,
            error: undefined,
            status: undefined,
            profile: state.profile,
        });
        sendAjaxRequest((ok, status, message) => {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            try {
                if (ok) {
                    const response = JSON.parse(message);
                    const bearer = response.bearer;
                    const sequence = response.sequence;
                    // Update Auth for next call
                    if (bearer) {
                        auth.setBearer(bearer);
                    }
                    if (sequence) {
                        auth.setSequence(sequence);
                    }
                    let clientDetailCode = ClientDetailCode.PROFILE_SUCCESS;
                    if (response.clientDetailCode) {
                        clientDetailCode = response.clientDetailCode;
                    }
                    let emailUpdated = false;
                    if (email && state.profile && email != ((_a = state.profile) === null || _a === void 0 ? void 0 : _a.email)) {
                        emailUpdated = true;
                    }
                    let emailVerified = (_b = state.profile) === null || _b === void 0 ? void 0 : _b.emailVerified;
                    if (code &&
                        state.profile &&
                        state.profile.emailVerified == false) {
                        emailVerified = true;
                    }
                    const newProfile = {
                        userName: (_c = state.profile) === null || _c === void 0 ? void 0 : _c.userName,
                        userId: (_d = state.profile) === null || _d === void 0 ? void 0 : _d.userId,
                        email: emailUpdated ? email : (_e = state.profile) === null || _e === void 0 ? void 0 : _e.email,
                        displayName: (_f = state.profile) === null || _f === void 0 ? void 0 : _f.displayName,
                        emailVerified: emailUpdated ? false : emailVerified,
                        terms: (_g = state.profile) === null || _g === void 0 ? void 0 : _g.terms,
                        accountType: (_h = state.profile) === null || _h === void 0 ? void 0 : _h.accountType,
                    };
                    set({
                        ok: true,
                        done: true,
                        start: false,
                        error: undefined,
                        status: status,
                        profile: newProfile,
                    });
                    callbacks.okCallback(clientDetailCode);
                }
                else {
                    let errorMessage = message;
                    console.error("ErrorMessage: " + errorMessage);
                    let clientDetailCode = ClientDetailCode.PROFILE_GENERAL_ERROR;
                    try {
                        const errorJson = JSON.parse(message);
                        errorMessage = errorJson === null || errorJson === void 0 ? void 0 : errorJson.error;
                        clientDetailCode = errorJson === null || errorJson === void 0 ? void 0 : errorJson.clientDetailCode;
                    }
                    catch (err) {
                        console.error("Malformed non-JSON response = " + err);
                    }
                    if (!errorMessage) {
                        errorMessage = "Unknown Failure";
                    }
                    // Clear Data
                    set({
                        ok: false,
                        done: true,
                        start: false,
                        error: errorMessage,
                        status: status,
                    });
                    callbacks.errorCallback(clientDetailCode, errorMessage);
                }
            }
            catch (error) {
                console.error(error);
                // Clear Data
                set({
                    ok: false,
                    done: true,
                    start: false,
                    error: error.message,
                    status: status,
                });
                callbacks.errorCallback(ClientDetailCode.PROFILE_GENERAL_ERROR, error.message);
            }
        }, "profile", payload, auth.bearer);
    },
    resendCode: (auth, callbacks) => __awaiter(void 0, void 0, void 0, function* () {
        console.log("resendCode");
        const state = get();
        const payload = {
            operation: "resend",
            userId: auth.userId ? auth.userId : "",
            profileUserId: auth.userId ? auth.userId : "",
            sequence: auth.sequence,
        };
        // Start Flow
        set({
            operation: ProfileOperation.ResendCode,
            ok: false,
            done: false,
            start: true,
            error: undefined,
            status: undefined,
            profile: state.profile,
        });
        sendAjaxRequest((ok, status, message) => {
            try {
                //          console.log("OK: " + ok);
                //          console.log("STATUS: " + status);
                //          console.log("MESSAGE: " + JSON.stringify(message));
                if (ok) {
                    let clientDetailCode = ClientDetailCode.PROFILE_SUCCESS;
                    // TODO: Set interface
                    const response = JSON.parse(message);
                    //              console.log("Server Response: " + JSON.stringify(response));
                    if (response.bearer) {
                        auth.setBearer(response.bearer);
                    }
                    if (response.sequence) {
                        auth.setSequence(response.sequence);
                    }
                    if (response.clientDetailCode) {
                        clientDetailCode = response.clientDetailCode;
                    }
                    set({
                        ok: true,
                        done: true,
                        start: false,
                        error: undefined,
                        status: status,
                        profile: state.profile,
                    });
                    callbacks.okCallback(clientDetailCode);
                }
                else {
                    let clientDetailCode = ClientDetailCode.PROFILE_GENERAL_ERROR;
                    let errorMessage = message;
                    console.error("ErrorMessage: " + errorMessage);
                    try {
                        const responseObj = JSON.parse(message);
                        errorMessage = responseObj === null || responseObj === void 0 ? void 0 : responseObj.error;
                        if (responseObj.clientDetailCode) {
                            clientDetailCode = responseObj.clientDetailCode;
                        }
                    }
                    catch (err) {
                        console.error("Malformed non-JSON response = " + err);
                    }
                    if (!errorMessage) {
                        errorMessage = "Unknown Failure";
                    }
                    // Clear Data
                    set({
                        ok: false,
                        done: true,
                        start: false,
                        error: errorMessage,
                        status: status,
                    });
                    callbacks.errorCallback(clientDetailCode, errorMessage);
                }
            }
            catch (error) {
                console.error(error);
                // Clear Data
                set({
                    ok: false,
                    done: true,
                    start: false,
                    error: error.message,
                    status: status,
                });
                callbacks.errorCallback(ClientDetailCode.PROFILE_GENERAL_ERROR, error.message);
            }
        }, "profile", payload, auth.bearer);
    }),
}));
export const deleteAccount = (callbacks, auth) => {
    console.log("profileStore.deleteAccount");
    if (!auth || !auth.userId || !auth.bearer) {
        callbacks.errorCallback(ClientDetailCode.MISSING_USER_ID, "User is not signed in.");
        return;
    }
    const payload = {
        operation: "deleteAccount",
        userId: auth.userId,
        profileUserId: auth.userId,
        sequence: auth.sequence,
    };
    sendAjaxRequest((ok, status, message) => {
        try {
            if (ok) {
                let clientDetailCode = ClientDetailCode.PROFILE_SUCCESS;
                const response = JSON.parse(message);
                console.log("Server Response: " + JSON.stringify(response));
                if (response.clientDetailCode) {
                    clientDetailCode = response.clientDetailCode;
                }
                callbacks.okCallback(clientDetailCode);
            }
            else {
                let clientDetailCode = ClientDetailCode.PROFILE_GENERAL_ERROR;
                let errorMessage = message;
                console.error("Error Message: " + errorMessage);
                try {
                    const responseObj = JSON.parse(message);
                    errorMessage = responseObj === null || responseObj === void 0 ? void 0 : responseObj.error;
                    if (responseObj.clientDetailCode) {
                        clientDetailCode = responseObj.clientDetailCode;
                    }
                }
                catch (err) {
                    console.error("Malformed non-JSON response = " + err);
                }
                if (!errorMessage) {
                    errorMessage = "Unknown Failure";
                }
                callbacks.errorCallback(clientDetailCode, errorMessage);
            }
        }
        catch (error) {
            console.error(error);
            callbacks.errorCallback(ClientDetailCode.PROFILE_GENERAL_ERROR, error.message);
        }
    }, "profile", payload, auth.bearer);
};
