import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Heading from "../Heading";
import Footer from "../Footer";
import { getDeviceInfo } from "../DeviceInfo";
import { Card, CardContent, Container, Stack, ThemeProvider, Typography, } from "@mui/material";
import getTheme from "../Theme";
import EngineeringIcon from "@mui/icons-material/Engineering";
import { getLayoutInfo } from "../LayoutInfo";
const backgroundImg = "./img/wordcloud_512.jpg";
const videoURL = "./background.mov";
export function Mess(props) {
    const layout = getLayoutInfo();
    const deviceInfo = getDeviceInfo();
    let videoWidth = "100%";
    let videoMarginTop = "50px";
    if (deviceInfo.isDesktopOrLaptop || deviceInfo.isBigScreen) {
        // Big Screen
        videoWidth = "60%";
        videoMarginTop = "50px";
    }
    else if (deviceInfo.isTabletOrMobile) {
        if (deviceInfo.isPortrait) {
            // Portrait Mobile
            videoWidth = "100%";
            videoMarginTop = "90px";
        }
        else {
            // Landscape Mobile
            videoWidth = "70%";
            videoMarginTop = "50px";
        }
    }
    const backgroundVideo = (_jsxs("video", Object.assign({ id: "background-video", loop: true, autoPlay: true, style: {
            textAlign: "center",
            verticalAlign: "middle",
            width: videoWidth,
            marginTop: videoMarginTop,
            display: "inline-flex",
        }, poster: backgroundImg }, { children: [_jsx("source", { src: videoURL, type: "video/quicktime" }), _jsx("source", { src: videoURL, type: "video/mp4" }), _jsx("source", { src: videoURL, type: "video/ogg" })] }), "background-video"));
    const content = (_jsxs(Stack, Object.assign({ sx: { padding: "2em" }, spacing: 3 }, { children: [_jsxs(Typography, Object.assign({ variant: "h5", color: "primary", sx: { textAlign: "center", width: "100%" } }, { children: [props.title, _jsxs(Typography, Object.assign({ variant: "body1", color: "#333333" }, { children: [_jsx(EngineeringIcon, { fontSize: "large" }), _jsx("br", {}), "UNDER CONSTRUCTION"] }))] })), _jsx(Card, Object.assign({ elevation: 10, sx: {
                    backgroundColor: "#FFFFFF",
                    padding: layout.cardPadding,
                    margin: layout.cardMargin,
                    alignContent: "center",
                    textAlign: "center",
                } }, { children: _jsx(CardContent, { children: _jsx(Stack, Object.assign({ spacing: 1, alignItems: "center", sx: { textAlign: "center" } }, { children: _jsxs("div", Object.assign({ style: {
                                width: "100%",
                                height: "100%",
                                display: "inline-block",
                                textAlign: "center",
                            } }, { children: [_jsx("br", {}), backgroundVideo, _jsx("br", {})] })) })) }) }))] })));
    const output = (_jsx("div", Object.assign({ style: { width: "100%", padding: 0, margin: 0 }, id: "outer" }, { children: _jsxs(ThemeProvider, Object.assign({ theme: getTheme() }, { children: [_jsx(Heading, {}), _jsx(Container, Object.assign({ sx: { backgroundColor: "#FFFFFF" } }, { children: content })), _jsx(Footer, {})] })) })));
    return output;
}
