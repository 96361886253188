import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getDeviceInfo } from "./DeviceInfo";
import Copyright from "./Copyright";
import { ThemeProvider } from "@mui/material/styles";
import getTheme from "./Theme";
import PortraitIcon from "@mui/icons-material/PhoneAndroid";
import LandscapeIcon from "@mui/icons-material/StayCurrentLandscape";
import DesktopIcon from "@mui/icons-material/LaptopChromebook";
import { Tooltip } from "@mui/material";
export default function Footer(props) {
    const deviceInfo = getDeviceInfo();
    let footerProps = props;
    if (!footerProps) {
        footerProps = {
            stickToBottom: false,
        };
    }
    let layoutIcon = (_jsx(Tooltip, Object.assign({ title: "Desktop Layout" }, { children: _jsx(DesktopIcon, { sx: { padding: "2px" } }) })));
    if (deviceInfo.isDesktopOrLaptop || deviceInfo.isBigScreen) {
        // Big Screen
        //    console.log("Resolution: Big Screen / Laptop");
    }
    else if (deviceInfo.isTabletOrMobile) {
        if (deviceInfo.isPortrait) {
            // Portrait Mobile
            //      console.log("Resolution: Mobile Portrait");
            layoutIcon = (_jsx(Tooltip, Object.assign({ title: "Portrait Layout" }, { children: _jsx(PortraitIcon, { sx: { padding: "2px" } }) })));
        }
        else {
            // Landscape Mobile
            //      console.log("Resolution: Mobile Landscape");
            layoutIcon = (_jsx(Tooltip, Object.assign({ title: "Portrait Layout" }, { children: _jsx(LandscapeIcon, { sx: { padding: "2px" } }) })));
        }
    }
    const theme = getTheme();
    const output = (_jsx(ThemeProvider, Object.assign({ theme: theme }, { children: _jsxs("div", Object.assign({ style: {
                width: "100%",
                paddingBottom: "3px",
                paddingTop: "3px",
                margin: 0,
                backgroundColor: theme.palette.backgrounds.light,
                display: "inline-block",
                position: footerProps.stickToBottom ? "absolute" : "relative",
                verticalAlign: "middle",
                lineHeight: "24px",
                bottom: 0,
                left: 0,
                textAlign: "center",
            }, id: "footerOuter" }, { children: [layoutIcon, _jsx(Copyright, {})] })) })));
    return output;
}
