import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Card, CardActions, CardContent, Link, Stack, Typography, } from "@mui/material";
import { deleteReport } from "../Store/ReportStore";
import { getLayoutInfo } from "../LayoutInfo";
import { UserAvatar } from "./UserAvatar";
import { PostCard } from "./PostCard";
import { getContentURL } from "../ContentAPI";
import { useNavigate } from "react-router-dom";
export const ReportElement = (props) => {
    const navigate = useNavigate();
    const layout = getLayoutInfo();
    const basePath = getContentURL() +
        "/u/" +
        props.report.channelOwnerId +
        "/" +
        props.report.channelId;
    return (_jsxs(Card, Object.assign({ elevation: 10, sx: {
            backgroundColor: "#FFFFFF",
            padding: layout.cardPadding,
            margin: layout.cardMargin,
            alignContent: "center",
            textAlign: "center",
        } }, { children: [_jsx(CardContent, Object.assign({ sx: {
                    padding: layout.cardContentPadding,
                    margin: layout.cardContentMargin,
                } }, { children: _jsxs(Stack, Object.assign({ direction: "column" }, { children: [_jsx(Typography, Object.assign({ variant: "body1", color: "#999999" }, { children: "REPORT TYPE" })), _jsx(Typography, Object.assign({ variant: "body1" }, { children: props.report.commentId ? "COMMENT" : "POST" })), _jsx(Typography, Object.assign({ variant: "body1", color: "#999999" }, { children: "REPORTED ID" })), _jsx(Typography, Object.assign({ variant: "body1" }, { children: props.report.reportId })), _jsx(Typography, Object.assign({ variant: "body1", color: "#999999" }, { children: "REPORTED BY" })), _jsx(UserAvatar, { keyStr: "report.card.avatar.reported-by." + props.report.reportId, userId: props.report.reportedByUserId, userName: props.report.reportedByDisplayName
                                ? props.report.reportedByDisplayName
                                : "", onUserClicked: () => {
                                navigate("/ProfileView?userId=" + props.report.reportedByUserId);
                            }, userNameVisible: true }), _jsx(Typography, Object.assign({ variant: "body1", color: "#999999" }, { children: "CHANNEL OWNER" })), _jsx(UserAvatar, { keyStr: "report.card.avatar.channel-owner." + props.report.reportId, userId: props.report.channelOwnerId, userName: "", onUserClicked: () => {
                                navigate("/ProfileView?userId=" + props.report.channelOwnerId);
                            }, userNameVisible: false }), _jsx(Typography, Object.assign({ variant: "body1", color: "#999999" }, { children: "POST OWNER" })), _jsx(UserAvatar, { keyStr: "report.card.avatar.post-owner." + props.report.reportId, userId: props.report.postOwnerId, userName: "", onUserClicked: () => {
                                navigate("/ProfileView?userId=" + props.report.postOwnerId);
                            }, userNameVisible: false }), props.report.commentByUserId && (_jsx(Typography, Object.assign({ variant: "body1", color: "#999999" }, { children: "COMMENT BY" }))), props.report.commentByUserId && (_jsx(UserAvatar, { keyStr: "report.card.avatar.comment-by." + props.report.reportId, userId: props.report.commentByUserId, userName: props.report.commentByDisplayName
                                ? props.report.commentByDisplayName
                                : "", onUserClicked: () => {
                                navigate("/ProfileView?userId=" + props.report.commentByUserId);
                            }, userNameVisible: !!props.report.commentByDisplayName })), props.report.commentId && (_jsx(Typography, Object.assign({ variant: "body1", color: "#999999" }, { children: "COMMENT ID" }))), props.report.commentId && (_jsx(Typography, Object.assign({ variant: "body1" }, { children: props.report.commentId }))), _jsx(Typography, Object.assign({ variant: "body1", color: "#999999" }, { children: "POST" })), props.report.postOwnerId && (_jsx(Link, Object.assign({ sx: { cursor: "pointer" }, onClick: () => {
                                navigate("/PostView?channelOwnerId=" +
                                    props.report.channelOwnerId +
                                    "&channelId=" +
                                    props.report.channelId +
                                    "&postId=" +
                                    props.report.postId);
                            } }, { children: _jsx(PostCard, { channelOwnerId: props.report.channelOwnerId, channelId: props.report.channelId, postId: props.report.postId, basePath: basePath, postJson: props.report.postId + ".json", keyStr: "report.postcard." +
                                    props.report.reportId +
                                    "." +
                                    props.report.postId, enableEditButtons: false, expandPostContent: false }, "report.postcard." +
                                props.report.reportId +
                                "." +
                                props.report.postId) })))] }), "report.stack.card." + props.report.reportId) })), _jsx(CardActions, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: _jsx(Button, Object.assign({ variant: "outlined", onClick: () => {
                        deleteReport(props.report, {
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            okCallback: (clientDetailCode, reports) => {
                                console.log("okCallback - delete ok");
                            },
                            errorCallback: (clientDetailCode, message) => {
                                console.error("errorCallback - delete error " +
                                    message +
                                    " (" +
                                    clientDetailCode +
                                    ")");
                            },
                        }, props.auth);
                    } }, { children: "Delete Report" })) }))] }), "report.card." + props.report.reportId));
};
