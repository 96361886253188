import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import SearchIcon from "@mui/icons-material/Search";
import { Button, Stack, TextField, Typography } from "@mui/material";
const searchLabelFontSize = window.innerWidth < 405 ? "small" : "medium";
const barWidth = window.innerWidth < 460 ? 150 : 250;
export const SearchBar = (props) => {
    return (_jsxs(Stack, Object.assign({ direction: "row", width: "100%", display: "flex", justifyContent: "center", margin: "8px", sx: { verticalAlign: "middle" } }, { children: [_jsx(Typography, Object.assign({ fontSize: searchLabelFontSize, variant: "body1", sx: { lineHeight: "42px", paddingRight: "12px" }, noWrap: true }, { children: props.label })), _jsx(TextField, { size: "small", fullWidth: false, sx: { width: barWidth }, InputProps: { style: { borderRadius: "20px", padding: 0, margin: 0 } }, onChange: (event) => {
                    const target = event.target;
                    console.log("searchBar.onChange = " + target.value);
                    props.onSearchChanged(target.value);
                } }), _jsx(Button, Object.assign({ sx: { verticalAlign: "middle", padding: 0, margin: 0 }, onClick: () => {
                    console.log("searchBar.onClick");
                    props.onSearchClicked();
                } }, { children: _jsx(SearchIcon, { fontSize: "large" }) }))] })));
};
