import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getDeviceInfo } from "./DeviceInfo";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import EngineeringIcon from "@mui/icons-material/Engineering";
import { Mess } from "./components/Mess";
/*
const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'firstName', headerName: 'First name', width: 130 },
  { field: 'lastName', headerName: 'Last name', width: 130 },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    width: 90,
  },
  {
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    valueGetter: (value, row) => `${row.firstName || ''} ${row.lastName || ''}`,
  },
];

const rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];

const paginationModel = { page: 0, pageSize: 5 };
*/
export function Messaging() {
    const deviceInfo = getDeviceInfo();
    if (deviceInfo.isDesktopOrLaptop || deviceInfo.isBigScreen) {
        // Big Screen
    }
    else if (deviceInfo.isTabletOrMobile) {
        if (deviceInfo.isPortrait) {
            // Portrait Mobile
        }
        else {
            // Landscape Mobile
        }
    }
    //  <CardActions sx={{ display: "flex", justifyContent: "center" }}>
    //<Button variant="contained" onClick={() => {}}>
    //</Button>
    //</CardActions>
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const content = (_jsxs(Stack, Object.assign({ sx: { padding: "2em" }, spacing: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "h6", color: "primary", sx: { textAlign: "center", width: "100%", padding: 0, margin: 0 } }, { children: "Messaging" })), _jsx("br", {}), _jsx(EngineeringIcon, {}), _jsxs(Stack, Object.assign({ spacing: 1, direction: "row", sx: { display: "flex", width: "100%" } }, { children: [_jsx(Card, Object.assign({ elevation: 5, sx: {
                            backgroundColor: "#FFFFFF",
                            padding: "4",
                            margin: 0,
                            alignContent: "center",
                            textAlign: "center",
                            flexGrow: 1,
                        } }, { children: _jsx(CardContent, { children: _jsx(Stack, Object.assign({ spacing: 1, alignItems: "center", sx: { textAlign: "center" } }, { children: _jsx("div", Object.assign({ style: {
                                        width: "100%",
                                        height: "100%",
                                        display: "inline-block",
                                        textAlign: "center",
                                    } }, { children: "CONVERSATIONS" })) })) }) })), _jsx(Card, Object.assign({ elevation: 5, sx: {
                            backgroundColor: "#FFFFFF",
                            padding: "4",
                            margin: 0,
                            alignContent: "center",
                            textAlign: "center",
                            flexGrow: 6,
                        } }, { children: _jsx(CardContent, { children: _jsx(Stack, Object.assign({ spacing: 1, alignItems: "center", sx: { textAlign: "center" } }, { children: _jsxs("div", Object.assign({ style: {
                                        width: "100%",
                                        height: "100%",
                                        display: "inline-block",
                                        textAlign: "center",
                                    } }, { children: [_jsx("br", {}), "READING PANEL", _jsx("br", {})] })) })) }) }))] }))] })));
    /*
    const output = (
      <div style={{ width: "100%", padding: 0, margin: 0 }} id="outer">
        <ThemeProvider theme={getTheme()}>
          <Heading />
          <Container sx={{ backgroundColor: "#FFFFFF" }}>{content}</Container>
          <Footer />
        </ThemeProvider>
      </div>
    );
    return output;
  */
    return _jsx(Mess, { title: "Messaging" });
}
