export const getLoginURL = () => {
    // Beta & Prod share a user pool
    const BASE_URL = "https://auth.mediashare.link/oauth2/authorize?response_type=token&client_id=5g9240lj7oj1ckpr7c9fsasss1&scope=openid+aws.cognito.signin.user.admin+profile+email+phone&redirect_uri=";
    if (window.location.href.includes("localhost:8080")) {
        return BASE_URL + "http://localhost:8080/authcb.html";
    }
    else if (window.location.href.includes("test.mediashare.link")) {
        return BASE_URL + "https://test.mediashare.link/authcb.html";
    }
    else {
        return BASE_URL + "https://www.mediashare.link/authcb.html";
    }
};
export const getCodeLoginURL = () => {
    // Beta & Prod share a user pool
    const BASE_URL = "https://auth.mediashare.link/oauth2/authorize?response_type=code&client_id=5g9240lj7oj1ckpr7c9fsasss1&scope=openid+aws.cognito.signin.user.admin+profile+email+phone&redirect_uri=";
    if (window.location.href.includes("localhost:8080")) {
        return BASE_URL + "http://localhost:8080/authcb.html";
    }
    else if (window.location.href.includes("test.mediashare.link")) {
        return BASE_URL + "https://test.mediashare.link/authcb.html";
    }
    else {
        return BASE_URL + "https://www.mediashare.link/authcb.html";
    }
};
export const doLogoutRedirect = () => {
    const BASE_URL = "https://auth.mediashare.link/logout?response_type=token&client_id=5g9240lj7oj1ckpr7c9fsasss1&scope=openid+aws.cognito.signin.user.admin+profile+email+phone&logout_uri=";
    if (window.location.href.includes("localhost:8080")) {
        window.location.href = BASE_URL + "http://localhost:8080/Logout";
    }
    else if (window.location.href.includes("test.mediashare.link")) {
        window.location.href = BASE_URL + "https://test.mediashare.link/Logout";
    }
    else {
        window.location.href = BASE_URL + "https://www.mediashare.link/Logout";
    }
};
