export const getAPIEndpoint = () => {
    const betaURL = "https://n20cpu0a7k.execute-api.us-west-2.amazonaws.com/prod/";
    const prodURL = "https://bl7lx6mjr1.execute-api.us-west-2.amazonaws.com/prod/";
    if (window.location.href.includes("localhost:8080")) {
        return betaURL;
    }
    else if (window.location.href.includes("test.mediashare.link")) {
        return betaURL;
    }
    else {
        return prodURL;
    }
};
export const sendAjaxRequest = (_callback, path, payload, // eslint-disable-line @typescript-eslint/no-explicit-any
bearer) => {
    // console.log('API - payload = ' + JSON.stringify(payload) + ', bearer = ' + bearer);
    const xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function () {
        if (xmlhttp.readyState == XMLHttpRequest.DONE) {
            // XMLHttpRequest.DONE == 4
            console.log("HTTP status: " + xmlhttp.status);
            const status = +xmlhttp.status;
            if (xmlhttp.status >= 200 && xmlhttp.status < 300) {
                console.log("responseText: " + xmlhttp.responseText);
                _callback(true, status, xmlhttp.responseText);
            }
            else {
                console.error("responseText: " + xmlhttp.responseText);
                _callback(false, status, xmlhttp.responseText);
            }
        }
    };
    const url = getAPIEndpoint() + path;
    xmlhttp.open("POST", url, true);
    xmlhttp.setRequestHeader("Content-type", "application/json");
    if (bearer) {
        xmlhttp.setRequestHeader("Authorization", "Basic " + bearer);
    }
    try {
        xmlhttp.send(JSON.stringify(payload));
    }
    catch (err) {
        console.error(err);
        _callback(false, 500, err.message);
    }
};
export const sendGETRequest = (_callback, path) => {
    console.log("API.GET: path = " + path);
    const xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function () {
        if (xmlhttp.readyState == XMLHttpRequest.DONE) {
            // XMLHttpRequest.DONE == 4
            console.log("API.GET: HTTP status: " + xmlhttp.status);
            const status = +xmlhttp.status;
            if (xmlhttp.status >= 200 && xmlhttp.status < 300) {
                console.log("API.GET: true - " + xmlhttp.responseText);
                _callback(true, status, xmlhttp.responseText);
            }
            else {
                console.error("API.GET: false - " + xmlhttp.responseText);
                _callback(false, status, xmlhttp.responseText);
            }
        }
    };
    const url = path;
    xmlhttp.open("GET", url, true);
    xmlhttp.setRequestHeader("Accept", "application/json");
    xmlhttp.send();
};
