import { createTheme, alpha } from "@mui/material/styles";
const violetDark = "#1F00EE";
const secondaryDark = "#ff6a33";
const blueDark = "#0000FF";
const theme = createTheme({
    palette: {
        primary: {
            main: alpha(violetDark, 0.55),
            light: alpha(violetDark, 0.25),
            dark: alpha(violetDark, 1.0),
            contrastText: "#FFFFFF",
        },
        secondary: {
            main: alpha(secondaryDark, 0.7),
            light: alpha(secondaryDark, 0.5),
            dark: alpha(secondaryDark, 1.0),
            contrastText: "#FFFFFF",
        },
        violet: {
            main: alpha(violetDark, 0.55),
            light: alpha(violetDark, 0.25),
            dark: alpha(violetDark, 1.0),
            contrastText: "#FFFFFF",
        },
        blue: {
            main: alpha(blueDark, 0.8),
            light: alpha(blueDark, 0.25),
            dark: "#222266",
            contrastText: "#FFFFFF",
        },
        black: {
            main: "#444444",
            light: "#DDDDDD",
            dark: "#222222",
            contrastText: "#EEEEEE",
        },
        backgrounds: {
            main: alpha(violetDark, 0.1),
            light: alpha(violetDark, 0.1),
            dark: "#CCCCCC",
            contrastText: alpha(violetDark, 0.55),
        },
    },
});
export default function getTheme() {
    return theme;
}
