import { getContentURL } from "../ContentAPI";
import { loadChannelData, } from "../DataUtils";
import { cacheBuster } from "../ImageUtils";
export const getChannelData = (channelOwnerId, channelId, 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
auth) => {
    return new Promise((resolve, reject) => {
        try {
            const callbacks = {
                okCallback: (clientDetailCode, data) => {
                    try {
                        const dataObj = JSON.parse(data);
                        const title = dataObj.title;
                        const description = dataObj.description;
                        const imageUrl = getContentURL() +
                            "/u/" +
                            channelOwnerId +
                            "/" +
                            channelId +
                            "/" +
                            (channelOwnerId == channelId
                                ? "feedImage_512.jpg"
                                : "channelImage_512.jpg");
                        // TODO: check image
                        const channelData = {
                            channelOwnerId: channelOwnerId,
                            channelId: channelId,
                            title,
                            description,
                            imageUrl,
                        };
                        resolve(channelData);
                    }
                    catch (err) {
                        reject(err);
                    }
                },
                errorCallback: (clientDetailCode, errorMessage) => {
                    reject(new Error("Unable to load channel data. " +
                        clientDetailCode +
                        " " +
                        errorMessage));
                },
            };
            loadChannelData("u", channelOwnerId, channelId, "feedData", "json", cacheBuster(), callbacks);
        }
        catch (err) {
            console.error("Unable to get channel data. " + err);
            reject(err);
        }
    });
};
