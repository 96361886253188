import { jsx as _jsx } from "react/jsx-runtime";
import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { getDeviceInfo } from "../DeviceInfo";
export const ContentCardStacks = (props) => {
    const [width, setWidth] = useState(0);
    const deviceInfo = getDeviceInfo();
    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [setWidth]);
    let columnCount = props.columnCount ? props.columnCount : 3;
    if (deviceInfo.isDesktopOrLaptop || deviceInfo.isBigScreen) {
        // Big Screen
    }
    else if (deviceInfo.isTabletOrMobile) {
        if (deviceInfo.isPortrait) {
            // Portrait Mobile
            columnCount = 1;
        }
        else {
            // Landscape Mobile
            if (width < 1000) {
                columnCount = columnCount - 1;
                if (columnCount < 1) {
                    columnCount = 1;
                }
            }
            if (width < 650) {
                columnCount = columnCount - 1;
                if (columnCount < 1) {
                    columnCount = 1;
                }
            }
        }
    }
    let currentIndex = 0;
    const rows = [];
    while (currentIndex < props.cards.length) {
        const rowElms = [];
        for (let i = 0; i < columnCount; ++i) {
            if (currentIndex < props.cards.length) {
                rowElms.push(props.cards[currentIndex++]);
            }
        }
        const row = (_jsx(Stack, Object.assign({ direction: "row", alignItems: "center", sx: { display: "flex", justifyContent: "center" } }, { children: rowElms }), "stack-row-" + currentIndex));
        rows.push(row);
    }
    const columnStack = (_jsx(Stack, Object.assign({ direction: "column" }, { children: rows }), "stack-column-" + currentIndex));
    return columnStack;
};
