var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { PublicContent } from "./Public";
import Login from "./Auth/Login";
import AuthCallback from "./Auth/AuthCallback";
import { Home } from "./Home";
import { MUIReference } from "./MUIReference";
import { LogoutPage } from "./LogoutPage";
import { useProfileAuthStore } from "./Store/ProfileStore";
import { About } from "./About";
import { Admin } from "./Admin";
import { Feed } from "./Feed";
import { Channels } from "./Channels";
import { Messaging } from "./Messaging";
import { Friends } from "./Friends";
import { Terms } from "./Terms";
import { ChannelView } from "./ChannelView";
import { ContentAdmin } from "./ContentAdmin";
import { PostView } from "./PostView";
import { ProfileView } from "./ProfileView";
import { ProfileSettings } from "./ProfileSettings";
import { Latest } from "./Latest";
import { useEffect } from "react";
import { useLogger } from "./logger";
import { FriendInviteLanding } from "./FriendInviteLanding";
import { NotificationsPage } from "./Notifications";
const LOGPREFIX = "App: ";
export default function App() {
    const logger = useLogger((state) => state);
    const bearer = useProfileAuthStore((state) => state.bearer);
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const registerPushNotifications = () => __awaiter(this, void 0, void 0, function* () {
            if (!("Notification" in window)) {
                logger.log(LOGPREFIX + "Push - NOT supported by browser");
                return;
            }
            if (Notification.permission === "granted") {
                // User has already granted permission
                logger.log(LOGPREFIX + "Push - Already granted OK");
                yield subscribeToPushNotifications();
            }
            else {
                logger.log(LOGPREFIX + "Push - Requesting Permissions");
                // Request permission from the user
                const permission = yield Notification.requestPermission();
                logger.log(LOGPREFIX + "Push - Permissions = " + permission);
                if (permission === "granted") {
                    yield subscribeToPushNotifications();
                }
            }
        });
        const subscribeToPushNotifications = () => __awaiter(this, void 0, void 0, function* () {
            logger.log(LOGPREFIX + "Push - Subscribe");
        });
        // TODO
        // registerPushNotifications();
        return () => { };
    }, []);
    let mainContent = _jsx(PublicContent, {});
    if (bearer) {
        logger.log(LOGPREFIX + "user IS signed in");
        mainContent = _jsx(Home, {});
    }
    else {
        logger.log(LOGPREFIX + "user is NOT signed in");
    }
    if (window.location.pathname.startsWith("/@")) {
        mainContent = _jsx(ChannelView, {});
    }
    return (_jsx(BrowserRouter, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/authcb", element: _jsx(AuthCallback, {}) }), _jsx(Route, { path: "/authcb.html", element: _jsx(AuthCallback, {}) }), _jsx(Route, { path: "/About", element: _jsx(About, {}) }), _jsx(Route, { path: "/Admin", element: _jsx(Admin, {}) }), _jsx(Route, { path: "/Terms", element: _jsx(Terms, {}) }), _jsx(Route, { path: "/Feed", element: _jsx(Feed, {}) }), _jsx(Route, { path: "/Channels", element: _jsx(Channels, {}) }), _jsx(Route, { path: "/Messaging", element: _jsx(Messaging, {}) }), _jsx(Route, { path: "/Friends", element: _jsx(Friends, {}) }), _jsx(Route, { path: "/FriendInvite", element: _jsx(FriendInviteLanding, {}) }), _jsx(Route, { path: "/login", element: _jsx(Login, {}) }), _jsx(Route, { path: "/Logout", element: _jsx(LogoutPage, {}) }), _jsx(Route, { path: "/muiref", element: _jsx(MUIReference, {}) }), _jsx(Route, { path: "/ChannelView", element: _jsx(ChannelView, {}) }), _jsx(Route, { path: "/ProfileView", element: _jsx(ProfileView, {}) }), _jsx(Route, { path: "/Profile", element: _jsx(ProfileSettings, {}) }), _jsx(Route, { path: "/ContentAdmin", element: _jsx(ContentAdmin, {}) }), _jsx(Route, { path: "/PostView", element: _jsx(PostView, {}) }), _jsx(Route, { path: "/share", element: window.location.search.includes("postId") ? (_jsx(PostView, {})) : (_jsx(ChannelView, {})) }), _jsx(Route, { path: "/Latest", element: _jsx(Latest, {}) }), _jsx(Route, { path: "/Notifications", element: _jsx(NotificationsPage, {}) }), _jsx(Route, { path: "/Home", element: mainContent }), _jsxs(Route, Object.assign({ path: "/", element: mainContent }, { children: [_jsx(Route, { index: true, element: mainContent }), _jsx(Route, { path: "*", element: mainContent })] }))] }) }));
}
