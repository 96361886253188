export const EMOJI_COMMENTS = "💬";
export const EMOJI_COWBOY_HAT = "🤠";
export const EMOJI_DISGUISED = "🥸";
export const EMOJI_NERD = "🤓";
export const EMOJI_MONOCLE = "🧐";
export const EMOJI_PARTYING = "🥳";
export const EMOJI_GRINNING = "😀";
export const EMOJI_GRINNING_BIG_EYES = "😃";
export const EMOJI_GRINNING_SMILING_EYES = "😄";
export const EMOJI_BEAMING_SMILING_EYES = "😁";
// https://unicode.org/emoji/charts/full-emoji-list.html
export const EMOJIS_LIST = [
    EMOJI_GRINNING,
    EMOJI_GRINNING_BIG_EYES,
    EMOJI_GRINNING_SMILING_EYES,
    EMOJI_BEAMING_SMILING_EYES,
    EMOJI_NERD,
    EMOJI_DISGUISED,
    EMOJI_COWBOY_HAT,
    EMOJI_MONOCLE,
    EMOJI_PARTYING,
];
