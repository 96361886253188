import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Container, LinearProgress, Stack, ThemeProvider, Typography, } from "@mui/material";
import Heading from "./Heading";
import Footer from "./Footer";
import getTheme from "./Theme";
import { useEffect, useState } from "react";
import { ContentCardStacks } from "./components/ContentCardStacks";
import { ContentCard } from "./components/ContentCard";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import { restoreSession, useProfileAuthStore } from "./Store/ProfileStore";
import { useNavigate } from "react-router-dom";
import { NewChannelCard } from "./components/NewChannelCard";
export const Channels = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loading, setLoading] = useState();
    const [myChannelsLoading, setMyChannelsLoading] = useState();
    const [myChannelsLoaded, setMyChannelsLoaded] = useState();
    const [addNewChannelFormVisible, setAddNewChannelFormVisible] = useState();
    const auth = useProfileAuthStore((state) => state);
    const navigate = useNavigate();
    const loadMyChannels = () => {
        console.log("loadMyChannels");
        // TODO
        setMyChannelsLoading(false);
        setMyChannelsLoaded(true);
    };
    useEffect(() => {
        const retVal = () => { };
        if (!auth.isSignedIn() || !auth.userId) {
            if (!restoreSession(auth)) {
                console.log("User NOT logged in - navigating...");
                navigate("/Login");
                return retVal;
            }
        }
        else {
            if (!myChannelsLoaded) {
                loadMyChannels();
            }
        }
        return retVal;
    }, [auth]);
    const cardsList = [];
    if (!addNewChannelFormVisible) {
        cardsList.push(_jsx(ContentCard, { title: "Trending", textColor: "main", backgroundColor: "#FFFFFF", keyStr: "trending.card" }, "trending.card"));
        cardsList.push(_jsx(ContentCard, { title: "Favorites", textColor: "main", backgroundColor: "#FFFFFF", keyStr: "favorites.card" }, "favorites.card"));
        cardsList.push(_jsx(ContentCard, { title: "Friends", textColor: "main", backgroundColor: "#FFFFFF", keyStr: "friends.card" }, "friends.card"));
    }
    const myChannelButtons = [];
    const addMyChannelButton = (_jsx(Button, Object.assign({ variant: "contained", onClick: () => {
            console.log("addMyChannelButton");
            setAddNewChannelFormVisible(true);
        } }, { children: _jsxs(Stack, Object.assign({ sx: { display: "flex", alignItems: "center" } }, { children: [_jsx(AddToQueueIcon, { fontSize: "large" }), _jsx("br", {}), _jsx(Typography, Object.assign({ variant: "body1" }, { children: "Create New Channel" }))] })) })));
    myChannelButtons.push(addMyChannelButton);
    let myChannelsContents = (_jsx(Stack, Object.assign({ sx: { marginBottom: "10px", marginTop: "5px" } }, { children: _jsx(ContentCardStacks, { cards: myChannelButtons }) })));
    if (myChannelsLoading) {
        myChannelsContents = _jsx(LinearProgress, {});
    }
    if (addNewChannelFormVisible && auth.userId) {
        myChannelsContents = (_jsx(NewChannelCard, { channelOwnerId: auth.userId, auth: auth, reload: () => {
                setAddNewChannelFormVisible(false);
                // TODO
            } }));
        cardsList.push(myChannelsContents);
    }
    else {
        cardsList.push(_jsx(ContentCard, { title: "My Channels", textColor: "main", backgroundColor: "#FFFFFF", keyStr: "trending.card", childComponent: myChannelsContents }, "trending.card"));
    }
    const columnStack = _jsx(ContentCardStacks, { cards: cardsList }, "cards.stack");
    const content = (_jsxs(Stack, Object.assign({ sx: { padding: 0, margin: 0 }, spacing: 0 }, { children: [_jsx(Typography, Object.assign({ variant: "h5", component: "div", color: "primary", gutterBottom: true, sx: { textAlign: "center", margin: 0, padding: 0 } }, { children: "Channels" }), "page.title"), _jsx(Typography, Object.assign({ variant: "body1", color: "#333333", width: "100%", sx: { display: "flex", justifyContent: "center" } }, { children: "UNDER CONSTRUCTION" })), loading && _jsx(LinearProgress, { sx: { width: "100%" } }), columnStack] }), "content"));
    const output = (_jsx("div", Object.assign({ style: { width: "100%", padding: 0, margin: 0 }, id: "outer" }, { children: _jsxs(ThemeProvider, Object.assign({ theme: getTheme() }, { children: [_jsx(Heading, {}), _jsx(Container, Object.assign({ sx: {
                        backgroundColor: "#FFFFFF",
                        width: "100%",
                        margin: 0,
                        padding: 0,
                    }, maxWidth: false }, { children: content }), "channels.container"), _jsx(Footer, {})] })) }), "page.div.channels"));
    return output;
};
