var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { sendAjaxRequest } from "../API";
import { DATA_UTIL_CLIENT_ERROR_LOAD_DATA_FAILED, DATA_UTIL_CLIENT_ERROR_MISSING_BEARER, DATA_UTIL_CLIENT_ERROR_SAVE_DATA_FAILED, } from "../DataUtils";
export const DEFAULT_REASON = "ads";
export const submitReport = (report, callbacks, auth) => __awaiter(void 0, void 0, void 0, function* () {
    console.log("submitReport: " + JSON.stringify(report));
    const apiCallback = (ok, status, message) => {
        if (ok) {
            console.log("submitReport: apiCallback OK");
            const obj = JSON.parse(message);
            console.log("submitReport: apiCallback OK - parsed output = " + JSON.stringify(obj));
            if (obj.bearer && obj.sequence) {
                console.log("submitReport: apiCallback OK - bearer and sequence set");
                auth.setBearer(obj.bearer);
                auth.setSequence(obj.sequence);
            }
            else {
                callbacks.errorCallback(DATA_UTIL_CLIENT_ERROR_MISSING_BEARER, "API response does NOT contain bearer and sequence - " + message);
                return;
            }
            try {
                const result = JSON.parse(message);
                if (!result) {
                    throw new Error("Unable to decode submit report response.");
                }
                callbacks.okCallback(obj.clientDetailCode);
            }
            catch (err) {
                console.error("submitReport: apiCallback ERROR = " + status + " " + message);
                const clientDetailCode = DATA_UTIL_CLIENT_ERROR_SAVE_DATA_FAILED;
                if (err instanceof Error) {
                    callbacks.errorCallback(clientDetailCode, err.message);
                }
                else {
                    callbacks.errorCallback(clientDetailCode, "Unable to save report.");
                }
            }
        }
        else {
            console.error("submitReport: apiCallback ERROR = " + status + " " + message);
            const clientDetailCode = DATA_UTIL_CLIENT_ERROR_SAVE_DATA_FAILED;
            callbacks.errorCallback(clientDetailCode, message);
        }
    };
    const path = "report";
    const input = {
        operation: "submitReport",
        report,
        sequence: auth.sequence,
    };
    console.log("submitReport: input " + JSON.stringify(input));
    sendAjaxRequest(apiCallback, path, input, auth.bearer);
});
export const listReports = (callbacks, auth) => __awaiter(void 0, void 0, void 0, function* () {
    console.log("listReports");
    const apiCallback = (ok, status, message) => {
        if (ok) {
            console.log("listReports: apiCallback OK");
            const obj = JSON.parse(message);
            console.log("listReports: apiCallback OK - parsed output = " + JSON.stringify(obj));
            if (obj.bearer && obj.sequence) {
                console.log("listReports: apiCallback OK - bearer and sequence set");
                auth.setBearer(obj.bearer);
                auth.setSequence(obj.sequence);
            }
            else {
                callbacks.errorCallback(DATA_UTIL_CLIENT_ERROR_MISSING_BEARER, "API response does NOT contain bearer and sequence - " + message);
                return;
            }
            try {
                const result = JSON.parse(message);
                if (!result) {
                    throw new Error("Unable to decode reports response.");
                }
                callbacks.okCallback(obj.clientDetailCode, result.reports);
            }
            catch (err) {
                console.error("listReports: apiCallback ERROR = " + status + " " + message);
                const clientDetailCode = DATA_UTIL_CLIENT_ERROR_LOAD_DATA_FAILED;
                if (err instanceof Error) {
                    callbacks.errorCallback(clientDetailCode, err.message);
                }
                else {
                    callbacks.errorCallback(clientDetailCode, "Unable to save report.");
                }
            }
        }
        else {
            console.error("listReports: apiCallback ERROR = " + status + " " + message);
            const clientDetailCode = DATA_UTIL_CLIENT_ERROR_LOAD_DATA_FAILED;
            callbacks.errorCallback(clientDetailCode, message);
        }
    };
    const path = "report";
    const input = {
        operation: "listReports",
        sequence: auth.sequence,
    };
    console.log("listReports: input " + JSON.stringify(input));
    sendAjaxRequest(apiCallback, path, input, auth.bearer);
});
export const deleteReport = (report, callbacks, auth) => __awaiter(void 0, void 0, void 0, function* () {
    console.log("deleteReport: " + JSON.stringify(report));
    const apiCallback = (ok, status, message) => {
        if (ok) {
            console.log("deleteReport: apiCallback OK");
            const obj = JSON.parse(message);
            console.log("deleteReport: apiCallback OK - parsed output = " + JSON.stringify(obj));
            if (obj.bearer && obj.sequence) {
                console.log("deleteReport: apiCallback OK - bearer and sequence set");
                auth.setBearer(obj.bearer);
                auth.setSequence(obj.sequence);
            }
            else {
                callbacks.errorCallback(DATA_UTIL_CLIENT_ERROR_MISSING_BEARER, "API response does NOT contain bearer and sequence - " + message);
                return;
            }
            try {
                const result = JSON.parse(message);
                if (!result) {
                    throw new Error("Unable to decode delete report response.");
                }
                callbacks.okCallback(obj.clientDetailCode);
            }
            catch (err) {
                console.error("deleteReport: apiCallback ERROR = " + status + " " + message);
                const clientDetailCode = DATA_UTIL_CLIENT_ERROR_SAVE_DATA_FAILED;
                if (err instanceof Error) {
                    callbacks.errorCallback(clientDetailCode, err.message);
                }
                else {
                    callbacks.errorCallback(clientDetailCode, "Unable to delete report.");
                }
            }
        }
        else {
            console.error("submitReport: apiCallback ERROR = " + status + " " + message);
            const clientDetailCode = DATA_UTIL_CLIENT_ERROR_SAVE_DATA_FAILED;
            callbacks.errorCallback(clientDetailCode, message);
        }
    };
    const path = "report";
    const input = {
        operation: "deleteReport",
        report,
        sequence: auth.sequence,
    };
    console.log("deleteReport: input " + JSON.stringify(input));
    sendAjaxRequest(apiCallback, path, input, auth.bearer);
});
