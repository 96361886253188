import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
const backgroundImg = "./img/wordcloud_512.jpg";
const videoURL = "./background.mov";
import Heading from "./Heading";
import Footer from "./Footer";
import { Card, CardContent, Container, Stack, ThemeProvider, Typography, } from "@mui/material";
import EngineeringIcon from "@mui/icons-material/Engineering";
import getTheme from "./Theme";
import { getLayoutInfo } from "./LayoutInfo";
import { getDeviceInfo } from "./DeviceInfo";
import { isProd } from "./ContentAPI";
export function PublicContent() {
    const deviceInfo = getDeviceInfo();
    const layout = getLayoutInfo();
    const backgroundVideo = (_jsx("div", Object.assign({ className: "backgroundVideo" }, { children: _jsxs("video", Object.assign({ id: "background-video", loop: true, autoPlay: true, style: {
                verticalAlign: "middle",
                width: layout.videoWidth,
            }, poster: backgroundImg }, { children: [_jsx("source", { src: videoURL, type: "video/quicktime" }), _jsx("source", { src: videoURL, type: "video/mp4" }), _jsx("source", { src: videoURL, type: "video/ogg" })] }), "background-video") }), "background-video-div"));
    const content = (_jsx(Card, Object.assign({ elevation: 10, sx: {
            backgroundColor: "#FFFFFF",
            padding: layout.cardPadding,
            margin: layout.cardMargin,
            alignContent: "center",
            textAlign: "center",
        } }, { children: _jsx(CardContent, Object.assign({ sx: {
                padding: layout.cardContentPadding,
                margin: layout.cardContentMargin,
            } }, { children: _jsxs(Stack, Object.assign({ spacing: 1, alignItems: "center", sx: { textAlign: "center" } }, { children: [!isProd() && (_jsx(Typography, Object.assign({ variant: "h6", color: "primary" }, { children: "Pardon our mess" }))), isProd() && (_jsx(Typography, Object.assign({ variant: "h6", color: "primary" }, { children: "Coming soon..." }))), _jsx(EngineeringIcon, { fontSize: "large" }), !isProd() && (_jsx(Typography, Object.assign({ variant: "body1", sx: {
                            textAlign: "left",
                            paddingLeft: layout.sidePadding,
                            paddingRight: layout.sidePadding,
                        } }, { children: "Welcome to the MediaShare.link social media network. You may find some features are missing or coming soon. We appreciate your patience. The word cloud below represents the vision for our completed site." }))), isProd() && (_jsx(Typography, Object.assign({ variant: "body1", sx: {
                            textAlign: "left",
                            paddingLeft: layout.sidePadding,
                            paddingRight: layout.sidePadding,
                        } }, { children: "The MediaShare.link social media network is currently under construction. We appreciate your patience. The word cloud below represents the vision for our completed site." }))), _jsx("br", {}), _jsx(Typography, Object.assign({ variant: "h6", color: "primary" }, { children: "Our Vision" })), _jsx("br", {}), _jsx("div", Object.assign({ style: {
                            width: "80%",
                            height: "80%",
                            display: "inline-block",
                            textAlign: "center",
                            margin: 0,
                            padding: 0,
                        } }, { children: backgroundVideo }))] })) })) })));
    const output = (_jsx("div", Object.assign({ style: { width: "100%", height: "100%", padding: 0, margin: 0 }, id: "outer" }, { children: _jsxs(ThemeProvider, Object.assign({ theme: getTheme() }, { children: [_jsx(Heading, {}), _jsxs(Container, Object.assign({ sx: { backgroundColor: "#FFFFFF" } }, { children: [_jsx("br", {}), content] })), _jsx(Footer, { stickToBottom: deviceInfo.isPortrait && deviceInfo.isTabletOrMobile })] })) })));
    return output;
}
