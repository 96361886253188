import { useMediaQuery } from "react-responsive";
export function getDeviceInfo() {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
    const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
    const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
    //console.log("isDesktopOrLaptop: " + isDesktopOrLaptop);
    //console.log("isTabletOrMobile: " + isTabletOrMobile);
    //console.log("isBigScreen: " + isBigScreen);
    //console.log("isPortrait: " + isPortrait);
    //console.log("isRetina: " + isRetina);
    return {
        isDesktopOrLaptop,
        isBigScreen,
        isTabletOrMobile,
        isPortrait,
        isRetina,
    };
}
