import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import Heading from "./Heading";
import Footer from "./Footer";
import { Button, CardActions, Container, LinearProgress, Stack, ThemeProvider, Typography, } from "@mui/material";
import getTheme from "./Theme";
import { ContentCardStacks } from "./components/ContentCardStacks";
import { loadFrontPageDiscovery } from "./DiscoveryStore";
import { useNavigate } from "react-router-dom";
import { restoreSession, useProfileAuthStore } from "./Store/ProfileStore";
import { GuideCard } from "./components/GuideCard";
import { useCacheBusterStore } from "./UrlUrils";
import { ContentCard } from "./components/ContentCard";
import { useLogger } from "./logger";
import { TopButtonMenu } from "./components/TopButtonMenu";
import { NewPostCard } from "./components/NewPostCard";
const LOGPREFIX = "Latest: ";
export function Latest() {
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [discoveryCards, setDiscoveryCards] = useState([]);
    const cacheBusterStore = useCacheBusterStore((state) => state);
    const auth = useProfileAuthStore((state) => state);
    const navigate = useNavigate();
    const logger = useLogger((state) => state);
    useEffect(() => {
        if (!auth.isSignedIn()) {
            restoreSession(auth);
        }
        else {
            if (auth &&
                auth.isSignedIn() &&
                auth.bearer &&
                auth.sequence &&
                !loading &&
                !loaded) {
                setLoading(true);
                loadFrontPageDiscovery({
                    okCallback: (elements) => {
                        setDiscoveryCards(elements);
                        setLoading(false);
                        setLoaded(true);
                    },
                    errorCallback: (errorMessage) => {
                        logger.error(LOGPREFIX +
                            "Latest.useEffect.discovery load failed: " +
                            errorMessage);
                        setErrorMessage(errorMessage);
                        setLoading(false);
                        setLoaded(true);
                    },
                }, false, navigate, cacheBusterStore, logger, auth);
            }
        }
        return () => { };
    }, [auth, loaded]);
    const cardsList = [];
    // TODO: Add member posts
    if (errorMessage) {
        cardsList.push(_jsx(ContentCard, { title: "ERROR", keyStr: "error.card.latest", textColor: "#000000", backgroundColor: "#FFEEEE", childComponent: _jsx(Typography, Object.assign({ variant: "body1" }, { children: errorMessage })), actions: _jsx(CardActions, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: _jsx(Button, Object.assign({ variant: "contained", onClick: () => {
                        setErrorMessage(undefined);
                        setLoaded(false);
                    } }, { children: "RETRY" })) })) }, "error.card.latest"));
    }
    else if (loaded) {
        if (auth.userId) {
            cardsList.push(_jsx(NewPostCard, { channelOwnerId: auth.userId, channelId: auth.userId, keyStr: "new.post.card", auth: auth, reload: function () {
                    setLoaded(false);
                } }));
        }
        discoveryCards.forEach((card) => {
            cardsList.push(card);
        });
        if (!loading) {
            cardsList.push(_jsx(GuideCard, {}, "guide.card"));
        }
    }
    const cardsStack = (_jsx(ContentCardStacks, { cards: cardsList, columnCount: 4 }, "cards.stack.latest"));
    const buttons = _jsx(TopButtonMenu, { current: "latest" });
    const output = (_jsx("div", Object.assign({ style: { width: "100%", height: "100%", padding: 0, margin: 0 }, id: "outer" }, { children: _jsxs(ThemeProvider, Object.assign({ theme: getTheme() }, { children: [_jsx(Heading, {}), _jsx(Container, Object.assign({ sx: {
                        backgroundColor: "#FFFFFF",
                        width: "100%",
                        padding: 0,
                        margin: 0,
                        display: "flex",
                        justifyContent: "center",
                    }, maxWidth: false }, { children: _jsxs(Stack, Object.assign({ direction: "column", sx: { display: "flex", justifyContent: "center", width: "100%" } }, { children: [buttons, loading && _jsx(LinearProgress, { sx: { margin: "2px" } }), cardsStack] })) })), _jsx(Footer, { stickToBottom: false })] })) })));
    return output;
}
