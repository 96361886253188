import { useEffect } from "react";
import { useProfileAuthStore } from "../Store/ProfileStore";
import { getCodeLoginURL } from "./AuthUtils";
export default function Login() {
    // Clear Login
    const authReset = useProfileAuthStore((state) => state.reset);
    useEffect(() => {
        const searchPath = window.location.search;
        if (searchPath && searchPath.startsWith("?to=")) {
            console.log("Login: search path = " + searchPath);
            // TODO: Post-auth redirect to cookie.
        }
        authReset();
        window.location.href = getCodeLoginURL();
    }, []);
    return null;
}
